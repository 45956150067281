import { BackgroundPaths } from "@/components/ui/background-paths";
import { LeadForm } from "@/components/LeadForm";
import { Card } from "@/components/ui/card";
import { useLanguage } from "@/context/LanguageContext";
import { SEO } from "@/components/SEO";

export default function GetStartedPage() {
  const { t } = useLanguage();

  return (
    <>
      <SEO 
        title="Get Started with Serfeo | AI Automation Solutions | Belgium"
        description="Start your AI journey with Serfeo. Contact us to discuss how our AI solutions can transform your business operations."
        schemaType="BreadcrumbList"
        canonicalOverride="https://serfeo.com/getstarted"
      />
      <div className="bg-black min-h-screen">
        {/* BackgroundPaths moved outside the container to span full width and be positioned at the top */}
        <BackgroundPaths
          title={t('getStartedPage.title')}
          description={t('getStartedPage.description')}
          className="mt-0 pt-0"
          textAlign="center"
          showGradients={false}
          showBlackGradient={true}
        />
        
        <div className="container mx-auto px-4 pb-20">
          {/* Process Cards */}
          <div className="max-w-5xl mx-auto mt-16 mb-16">
            <h2 className="text-3xl font-bold text-center mb-10 text-white">{t('getStartedPage.howItWorks')}</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* Consultation Card */}
              <div className="relative group">
                {/* Blue glow on hover */}
                <div className="absolute -inset-1 bg-blue-500/20 rounded-lg blur-lg opacity-0 group-hover:opacity-30 transition-opacity duration-300"></div>
                
                <Card className="bg-gray-900/60 border-gray-800 group-hover:border-blue-900/30 h-full p-6 transition-all duration-300">
                  <div className="flex flex-col h-full">
                    <div className="flex items-center mb-4">
                      <div className="flex-shrink-0 w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center text-white font-bold">
                        1
                      </div>
                      <h3 className="ml-4 text-xl font-bold text-white">{t('getStartedPage.steps.consultation.title')}</h3>
                    </div>
                    <p className="text-gray-300 mt-2">
                      {t('getStartedPage.steps.consultation.description')}
                    </p>
                  </div>
                </Card>
              </div>
              
              {/* Customization Card */}
              <div className="relative group">
                {/* Blue glow on hover */}
                <div className="absolute -inset-1 bg-blue-500/20 rounded-lg blur-lg opacity-0 group-hover:opacity-30 transition-opacity duration-300"></div>
                
                <Card className="bg-gray-900/60 border-gray-800 group-hover:border-blue-900/30 h-full p-6 transition-all duration-300">
                  <div className="flex flex-col h-full">
                    <div className="flex items-center mb-4">
                      <div className="flex-shrink-0 w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center text-white font-bold">
                        2
                      </div>
                      <h3 className="ml-4 text-xl font-bold text-white">{t('getStartedPage.steps.customization.title')}</h3>
                    </div>
                    <p className="text-gray-300 mt-2">
                      {t('getStartedPage.steps.customization.description')}
                    </p>
                  </div>
                </Card>
              </div>
              
              {/* Implementation Card */}
              <div className="relative group">
                {/* Blue glow on hover */}
                <div className="absolute -inset-1 bg-blue-500/20 rounded-lg blur-lg opacity-0 group-hover:opacity-30 transition-opacity duration-300"></div>
                
                <Card className="bg-gray-900/60 border-gray-800 group-hover:border-blue-900/30 h-full p-6 transition-all duration-300">
                  <div className="flex flex-col h-full">
                    <div className="flex items-center mb-4">
                      <div className="flex-shrink-0 w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center text-white font-bold">
                        3
                      </div>
                      <h3 className="ml-4 text-xl font-bold text-white">{t('getStartedPage.steps.implementation.title')}</h3>
                    </div>
                    <p className="text-gray-300 mt-2">
                      {t('getStartedPage.steps.implementation.description')}
                    </p>
                  </div>
                </Card>
              </div>
            </div>
          </div>
          
          <div className="max-w-2xl mx-auto mt-20">
            <LeadForm source="get_started" />
          </div>
        </div>
      </div>
    </>
  );
}