import { cn } from "@/lib/utils"

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {}

export function Card({ className, ...props }: CardProps) {
  return (
    <div
      className={cn(
        "rounded-lg border bg-card text-card-foreground shadow-sm backdrop-blur-sm relative z-10",
        "after:absolute after:inset-0 after:-z-10 after:bg-blue-950/5 after:rounded-lg after:blur-md after:opacity-30",
        className
      )}
      {...props}
    />
  )
}