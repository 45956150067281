import React from 'react';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { MessageSquare, Phone, CheckCircle2 } from 'lucide-react';
import { useLanguage } from '@/context/LanguageContext';

interface FeatureItem {
  text: string;
}

interface SolutionCardProps {
  title: string;
  description: string;
  features: FeatureItem[];
  type: 'chatbot' | 'voice';
  linkTo?: string;
  isClickable?: boolean;
}

export const RestaurantSolutionCard = ({
  title,
  description,
  features,
  type,
  linkTo,
  isClickable = true
}: SolutionCardProps) => {
  // If the card is not clickable or there's no link, render a div
  // Otherwise render a Link
  const CardWrapper = (isClickable && linkTo) ? Link : 'div';
  
  // Add "(Coming Soon)" for voice agent card title
  const displayTitle = type === 'voice' ? `${title} (Coming Soon)` : title;
  
  return (
    <CardWrapper
      to={isClickable && linkTo ? linkTo : '#'}
      className={cn(
        "relative overflow-hidden rounded-2xl border border-gray-800 bg-gray-900/60 backdrop-blur-sm shadow-lg",
        "p-8 flex flex-col h-full gap-6 transition-all duration-300",
        isClickable && linkTo && "hover:-translate-y-1 hover:border-blue-900/30 hover:shadow-xl hover:shadow-blue-900/5 cursor-pointer"
      )}
    >
      {/* Subtle blue glow effect */}
      <div className="absolute -inset-1 bg-blue-500/10 rounded-2xl blur-lg opacity-0 group-hover:opacity-30 transition-opacity duration-300"></div>
      
      {/* Icon */}
      <div className="text-blue-400 mb-2">
        {type === 'chatbot' ? (
          <MessageSquare size={48} className="opacity-90" />
        ) : (
          <Phone size={48} className="opacity-90" />
        )}
      </div>
      
      {/* Content */}
      <div>
        <h3 className="text-2xl font-bold text-white mb-4">{displayTitle}</h3>
        <p className="text-gray-300 mb-6">{description}</p>
        
        {/* Features List */}
        <div className="space-y-3">
          {features.map((feature, index) => (
            <div key={index} className="flex items-center text-gray-400">
              <CheckCircle2 className="w-5 h-5 text-blue-400 mr-3 flex-shrink-0" />
              <span>{feature.text}</span>
            </div>
          ))}
        </div>
      </div>
    </CardWrapper>
  );
};

export const RestaurantSolutionCardsSection = () => {
  const { t } = useLanguage();
  
  const chatbotFeatures = [
    { text: t('restaurantSolutions.chatbot.features.support') },
    { text: t('restaurantSolutions.chatbot.features.menu') },
    { text: t('restaurantSolutions.chatbot.features.allergen') },
    { text: t('restaurantSolutions.chatbot.features.multilingual') },
    { text: t('restaurantSolutions.chatbot.features.reservation') }
  ];
  
  const voiceFeatures = [
    { text: t('restaurantSolutions.voice.features.support') },
    { text: t('restaurantSolutions.voice.features.menu') },
    { text: t('restaurantSolutions.voice.features.faq') },
    { text: t('restaurantSolutions.voice.features.availability') },
    { text: t('restaurantSolutions.voice.features.reservations') }
  ];
  
  return (
    <div className="container mx-auto px-4 py-12">
      <h2 className="text-3xl font-bold text-center mb-10">{t('solutions.title')}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <RestaurantSolutionCard
          title={t('restaurantSolutions.chatbot.title')}
          description={t('restaurantSolutions.chatbot.description')}
          features={chatbotFeatures}
          type="chatbot"
          linkTo="/chatbot"
          isClickable={true}
        />
        <RestaurantSolutionCard
          title={t('restaurantSolutions.voice.title')}
          description={t('restaurantSolutions.voice.description')}
          features={voiceFeatures}
          type="voice"
          linkTo="/voice-agent"
          isClickable={false}
        />
      </div>
    </div>
  );
};