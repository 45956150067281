import { cn } from "@/lib/utils";
import {
  IconAdjustmentsBolt,
  IconCloud,
  IconCurrencyDollar,
  IconEaseInOut,
  IconHeart,
  IconHelp,
  IconRouteAltLeft,
  IconTerminal2,
} from "@tabler/icons-react";
import { useLanguage } from "@/context/LanguageContext";

export function HotelFeatures() {
  const { language, t } = useLanguage();
  
  const features = [
    {
      title: t('hotelFeatures.roomBooking.title'),
      description: t('hotelFeatures.roomBooking.description'),
      icon: <IconTerminal2 />,
    },
    {
      title: t('hotelFeatures.guestExperience.title'),
      description: t('hotelFeatures.guestExperience.description'),
      icon: <IconEaseInOut />,
    },
    {
      title: t('hotelFeatures.dynamicPricing.title'),
      description: t('hotelFeatures.dynamicPricing.description'),
      icon: <IconCurrencyDollar />,
    },
    {
      title: t('hotelFeatures.virtualConcierge.title'),
      description: t('hotelFeatures.virtualConcierge.description'),
      icon: <IconCloud />,
    },
    {
      title: t('hotelFeatures.multiChannel.title'),
      description: t('hotelFeatures.multiChannel.description'),
      icon: <IconRouteAltLeft />,
    },
    {
      title: t('hotelFeatures.customerSupport.title'),
      description: t('hotelFeatures.customerSupport.description'),
      icon: <IconHelp />,
    },
    {
      title: t('hotelFeatures.satisfaction.title'),
      description: t('hotelFeatures.satisfaction.description'),
      icon: <IconAdjustmentsBolt />,
    },
    {
      title: t('hotelFeatures.loyalty.title'),
      description: t('hotelFeatures.loyalty.description'),
      icon: <IconHeart />,
    },
  ];
  
  return (
    <div className="features-section grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 relative z-10 py-10 max-w-7xl mx-auto bg-gray-900/50 backdrop-blur-sm rounded-xl">
      {features.map((feature, index) => (
        <Feature key={feature.title} {...feature} index={index} />
      ))}
    </div>
  );
}

const Feature = ({
  title,
  description,
  icon,
  index,
}: {
  title: string;
  description: string;
  icon: React.ReactNode;
  index: number;
}) => {
  return (
    <div
      className={cn(
        "flex flex-col lg:border-r py-10 relative group/feature border-blue-900/30",
        (index === 0 || index === 4) && "lg:border-l border-blue-900/30",
        index < 4 && "lg:border-b border-blue-900/30"
      )}
    >
      {index < 4 && (
        <div className="opacity-0 group-hover/feature:opacity-100 transition duration-200 absolute inset-0 h-full w-full bg-gradient-to-t from-blue-500/20 to-transparent pointer-events-none" />
      )}
      {index >= 4 && (
        <div className="opacity-0 group-hover/feature:opacity-100 transition duration-200 absolute inset-0 h-full w-full bg-gradient-to-b from-blue-500/20 to-transparent pointer-events-none" />
      )}
      <div className="mb-4 relative z-10 px-10 text-blue-400">
        {icon}
      </div>
      <div className="text-lg font-bold mb-2 relative z-10 px-10">
        <div className="absolute left-0 inset-y-0 h-6 group-hover/feature:h-8 w-1 rounded-tr-full rounded-br-full bg-blue-500/50 group-hover/feature:bg-blue-500 transition-all duration-200 origin-center" />
        <span className="group-hover/feature:translate-x-2 transition duration-200 inline-block text-white">
          {title}
        </span>
      </div>
      <p className="text-sm text-gray-300 max-w-xs relative z-10 px-10">
        {description}
      </p>
    </div>
  );
};