"use client";
import { cn } from "@/lib/utils";
import React, { ReactNode } from "react";

interface AuroraBackgroundProps extends React.HTMLProps<HTMLDivElement> {
  children: ReactNode;
  showRadialGradient?: boolean;
}

export const AuroraBackground = ({
  className,
  children,
  showRadialGradient = true,
  ...props
}: AuroraBackgroundProps) => {
  return (
    <div className="relative w-full min-h-[60vh] sm:min-h-screen bg-black">
      {/* Blue glow base layer */}
      <div className="absolute inset-0 bg-blue-900/10 overflow-hidden">
        <div className="absolute -inset-[100px] blur-[120px] opacity-30">
          <div className="absolute top-1/4 left-1/4 w-[500px] h-[500px] rounded-full bg-blue-500/30"></div>
          <div className="absolute bottom-1/3 right-1/4 w-[600px] h-[600px] rounded-full bg-blue-400/20"></div>
          <div className="absolute top-2/3 left-1/3 w-[400px] h-[400px] rounded-full bg-indigo-500/20"></div>
        </div>
      </div>
      
      <div
        className={cn(
          "absolute inset-0 overflow-hidden",
          className
        )}
        {...props}
      >
        {/* Darker overlay for better text contrast on mobile */}
        <div className="absolute inset-0 bg-black opacity-90 sm:opacity-85"></div>
        
        {/* Optimized aurora effect for mobile */}
        <div
          className={cn(
            "absolute inset-[-100px] sm:inset-[-200px] opacity-50 sm:opacity-60",
            `[--white-gradient:repeating-linear-gradient(100deg,var(--white)_0%,var(--white)_7%,var(--transparent)_10%,var(--transparent)_12%,var(--white)_16%)]
            [--dark-gradient:repeating-linear-gradient(100deg,var(--black)_0%,var(--black)_7%,var(--transparent)_10%,var(--transparent)_12%,var(--black)_16%)]
            [--aurora:repeating-linear-gradient(100deg,var(--blue-500)_10%,var(--indigo-300)_15%,var(--blue-300)_20%,var(--violet-200)_25%,var(--blue-400)_30%)]
            [background-image:var(--aurora)]
            [background-size:300%_300%] sm:[background-size:400%_400%]
            [background-position:50%_50%]
            filter blur-[60px] sm:blur-[100px]
            after:content-[""]
            after:absolute
            after:inset-0
            after:[background-image:var(--aurora)]
            after:[background-size:150%_150%] sm:after:[background-size:200%_200%]
            after:animate-aurora
            after:[background-attachment:fixed]
            after:mix-blend-soft-light
            pointer-events-none
            will-change-transform`,
            showRadialGradient &&
              `[mask-image:radial-gradient(ellipse_at_center,black_30%,transparent_70%)] 
              sm:[mask-image:radial-gradient(ellipse_at_center,black_50%,transparent_100%)]`
          )}
        ></div>
        
        {/* Blue glow overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-blue-900/5 via-blue-800/5 to-transparent mix-blend-overlay"></div>
        
        {/* Optimized gradient overlay for mobile */}
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/10 to-black"></div>
      </div>
      <div className="relative z-10">{children}</div>
    </div>
  );
};