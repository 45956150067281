import { Hero } from "@/components/ui/animated-hero";
import { BackgroundPaths } from "@/components/ui/background-paths";
import { ChatbotPricingSection } from "@/components/ui/chatbot-pricing-section";
import { FeatureCards } from "@/components/ui/feature-cards";
import { BeamsBackground } from "@/components/ui/beams-background";
import { FaqSection } from "@/components/ui/faq-section";
import { useNavigate } from 'react-router-dom';
import { useLanguage } from "@/context/LanguageContext";
import { SEO } from "@/components/SEO";

export default function ChatbotPage() {
  const navigate = useNavigate();
  const { t, language } = useLanguage();

  // Define custom FAQ items for both languages
  const faqItems = language === 'NE' 
    ? [
      // Dutch FAQ items
      {
        question: 'Hoe integreert de AI-chatbot met mijn bestaande systemen?',
        answer: 'Onze chatbots zijn ontworpen om naadloos te integreren met vrijwel elke bestaande website of platform. We bieden aangepaste API-integraties en kunnen verbinding maken met uw CRM, reserveringssysteem, inventarisbeheer en meer.'
      },
      {
        question: 'Kan de chatbot meerdere talen verwerken?',
        answer: 'Ja, onze chatbots ondersteunen meertalige communicatie en kunnen automatisch van taal wisselen om klanten in hun voorkeurstaal te bedienen, wat een persoonlijkere ervaring biedt.'
      },
      {
        question: 'Hoe lang duurt het om de chatbotoplossing te implementeren?',
        answer: 'De implementatietijd varieert afhankelijk van de complexiteit, maar meestal kunnen we binnen 2-4 weken een volledig functionele chatbot opstellen en trainen na onze eerste consultatie.'
      },
      {
        question: 'Wat gebeurt er wanneer de chatbot een vraag niet kan beantwoorden?',
        answer: 'Wanneer een chatbot een vraag niet kan beantwoorden, kan deze worden geconfigureerd om naadloos door te schakelen naar een menselijke medewerker, een ticket aan te maken of een follow-up e-mail te sturen, zodat geen enkele klantinteractie verloren gaat.'
      },
      {
        question: 'Hoe veilig worden de klantgegevens door de chatbot verwerkt?',
        answer: 'Wij nemen gegevensbescherming zeer serieus. Alle klantgegevens worden versleuteld, veilig opgeslagen en verwerkt in overeenstemming met GDPR en andere relevante privacywetgeving.'
      },
      {
        question: 'Waarom betaal ik een eenmalige opzetkost en een maandelijkse fee?',
        answer: 'De eenmalige opzetkost is bedoeld voor de volledige personalisatie en training van de AI, zodat deze perfect aansluit bij uw restaurant. De maandelijkse fee dekt: De platformkosten voor het gebruik van de AI. Doorlopende ondersteuning, inclusief updates en aanpassingen aan het menu of workflows. Wij hanteren géén extra uurtarieven voor aanpassingen—alles is inbegrepen in de maandelijkse fee.'
      },
      {
        question: 'Is de prijs inclusief of exclusief BTW?',
        answer: 'Momenteel zijn wij vrijgesteld van BTW omdat we een klein bedrijf zijn. Daarom is BTW niet van toepassing op onze diensten.'
      }
    ] 
    : [
      // English FAQ items
      {
        question: 'How does the AI chatbot integrate with my existing systems?',
        answer: 'Our chatbots are designed to integrate seamlessly with virtually any existing website or platform. We offer custom API integrations and can connect with your CRM, reservation system, inventory management, and more.'
      },
      {
        question: 'Can the chatbot handle multiple languages?',
        answer: 'Yes, our chatbots support multilingual communication, automatically switching languages to serve customers in their preferred language, providing a more personalized experience.'
      },
      {
        question: 'How long does it take to implement the chatbot solution?',
        answer: 'Implementation time varies depending on complexity, but typically we can set up and train a fully functional chatbot within 2-4 weeks after our initial consultation.'
      },
      {
        question: "What happens when the chatbot can't answer a question?",
        answer: 'When a chatbot cannot answer a question, it can be configured to seamlessly transfer to a human agent, create a ticket, or send a follow-up email, ensuring no customer interaction is lost.'
      },
      {
        question: 'How secure is the customer data handled by the chatbot?',
        answer: 'We take data protection very seriously. All customer data is encrypted, securely stored, and processed in compliance with GDPR and other relevant privacy legislation.'
      },
      {
        question: 'Why do I pay a one-time setup fee and a monthly fee?',
        answer: 'The one-time setup fee is intended for the complete personalization and training of the AI, ensuring it perfectly aligns with your restaurant. The monthly fee covers: The platform costs for using the AI. Ongoing support, including updates and adjustments to the menu or workflows. We do NOT charge extra hourly rates for adjustments—everything is included in the monthly fee.'
      },
      {
        question: 'Is the price inclusive or exclusive of VAT?',
        answer: 'Currently, we are exempt from VAT because we are a small business. Therefore, VAT is not applicable to our services.'
      }
    ];

  // Create FAQ schema data
  const faqSchemaData = {
    mainEntity: faqItems.map(item => ({
      '@type': 'Question',
      name: item.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.answer
      }
    }))
  };

  return (
    <>
      <SEO 
        title="AI Chatbot Solutions | Serfeo | 24/7 Customer Support"
        description="Transform your customer service with Serfeo's AI-powered chatbot solutions. Handle customer inquiries, provide information, and perform tasks 24/7."
        schemaType="FAQPage"
        schemaData={faqSchemaData}
        canonicalOverride="https://serfeo.com/chatbot"
      />
      <div className="bg-black min-h-screen">
        <BeamsBackground intensity="medium">
          <Hero
            titles={["Automated", "Customer Service", "Save Time"]}
            subtitle={t('chatbotPage.hero.subtitle')}
            ctaText={t('chatbotPage.hero.ctaText')}
            onCtaClick={() => navigate('/getstarted')}
            showBackButton={true}
            useAuroraBackground={false}
            makeEveryText={t('hero.makeEvery')}
          />
        </BeamsBackground>
        
        {/* Feature cards section */}
        <FeatureCards type="chatbot" />
        
        <div className="pt-4">
          <h2 className="text-3xl font-bold text-center mb-2">{t('chatbotPage.pricingPlans')}</h2>
          <ChatbotPricingSection />
        </div>
        
        {/* FAQ Section - using custom items that include the new questions for both languages */}
        <FaqSection
          title={t('faq.title')}
          description={t('faq.description')}
          items={faqItems}
        />
        
        {/* BackgroundPaths moved to bottom, just above footer */}
        <BackgroundPaths
          title={t('chatbotPage.nextGen')}
          description={t('chatbotPage.description')}
          buttonText={t('cta.button')}
          onButtonClick={() => navigate('/getstarted')}
        />
      </div>
    </>
  );
}