import { cn } from "@/lib/utils";
import { CheckIcon } from "lucide-react";
import { useLanguage } from "@/context/LanguageContext";

interface FeatureCardProps {
  title: string;
  description: string;
  className?: string;
}

const FeatureCard = ({ title, description, className }: FeatureCardProps) => (
  <div className={cn(
    "relative overflow-hidden rounded-xl border border-gray-800 bg-gray-900/80 backdrop-blur-sm p-6",
    "hover:border-blue-900/30 hover:bg-gray-900/90 transition-all duration-200",
    "shadow-lg shadow-blue-900/5 hover:shadow-blue-900/10",
    className
  )}>
    {/* Subtle blue glow */}
    <div className="absolute -inset-1 bg-blue-500/5 rounded-xl blur-md opacity-0 group-hover:opacity-30 transition-opacity"></div>
    
    <h3 className="text-xl font-bold text-white mb-3">{title}</h3>
    <p className="text-gray-400">{description}</p>
  </div>
);

interface IntegrationCardProps {
  title: string;
  description: string;
  features: string[];
  className?: string;
}

const IntegrationCard = ({ title, description, features, className }: IntegrationCardProps) => (
  <div className={cn(
    "relative overflow-hidden rounded-xl border border-gray-800 bg-gray-900/80 backdrop-blur-sm p-6",
    "hover:border-blue-900/30 hover:bg-gray-900/90 transition-all duration-200",
    "shadow-lg shadow-blue-900/5 hover:shadow-blue-900/10",
    className
  )}>
    {/* Subtle blue glow */}
    <div className="absolute -inset-1 bg-blue-500/5 rounded-xl blur-md opacity-0 group-hover:opacity-30 transition-opacity"></div>
    
    <h3 className="text-xl font-bold text-white mb-3">{title}</h3>
    <p className="text-gray-400 mb-4">{description}</p>
    
    <ul className="space-y-2">
      {features.map((feature, index) => (
        <li key={index} className="flex items-start text-gray-300">
          <span className="text-blue-400 mr-2">›</span>
          <span>{feature}</span>
        </li>
      ))}
    </ul>
  </div>
);

interface FeatureCardsProps {
  type: "chatbot" | "voice";
}

export function FeatureCards({ type }: FeatureCardsProps) {
  const { t } = useLanguage();
  
  const chatbotFeatures = [
    { 
      title: "Reservation Module", 
      description: "We seamlessly integrate your reservation system, providing an all-in-one solution."
    },
    { 
      title: "Customer Data Storage", 
      description: "Securely store customer data in your database to expand your customer base."
    },
    { 
      title: "Gift Cards", 
      description: "Help customers effortlessly purchase and add gift cards to their cart."
    },
    { 
      title: "Multichannel Support", 
      description: "Integrate with all your platforms, including Facebook, Instagram, and WhatsApp."
    }
  ];
  
  const voiceFeatures = [
    { 
      title: t('featuresSection.voice.support.title'), 
      description: t('featuresSection.voice.support.description')
    },
    { 
      title: t('featuresSection.voice.natural.title'), 
      description: t('featuresSection.voice.natural.description')
    },
    { 
      title: t('featuresSection.voice.transcription.title'),
      description: t('featuresSection.voice.transcription.description')
    },
    { 
      title: t('featuresSection.voice.multilingual.title'), 
      description: t('featuresSection.voice.multilingual.description')
    }
  ];
  
  const chatbotIntegrations = [
    {
      title: "Custom Workflows",
      description: "Integrate with third-party software or create tailored workflows based on your needs.",
      features: [
        "Real-time availability checks",
        "Automatic confirmation emails",
        "Modification handling"
      ]
    }
  ];
  
  const voiceIntegrations = [
    {
      title: t('featuresSection.voice.integration.title'),
      description: t('featuresSection.voice.integration.description'),
      features: [
        "Automatic call routing",
        "Call escalation to human agents",
        "Call recording and analytics"
      ]
    }
  ];
  
  const features = type === "chatbot" ? chatbotFeatures : voiceFeatures;
  const integrations = type === "chatbot" ? chatbotIntegrations : voiceIntegrations;
  
  return (
    <div className="container mx-auto px-4 py-12">
      <div className="space-y-12">
        <div>
          <h2 className="text-2xl font-bold text-white mb-6">{t('chatbotPage.features')}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {features.map((feature, index) => (
              <FeatureCard 
                key={index}
                title={feature.title}
                description={feature.description}
              />
            ))}
          </div>
        </div>
        
        <div>
          <h2 className="text-2xl font-bold text-white mb-6">{t('solutions.title')}</h2>
          <div className="grid grid-cols-1 gap-6">
            {integrations.map((integration, index) => (
              <IntegrationCard 
                key={index}
                title={integration.title}
                description={integration.description}
                features={integration.features}
              />
            ))}
          </div>
        </div>
        
        <div>
          <h2 className="text-2xl font-bold text-white mb-6">Personalization</h2>
          <div className="grid grid-cols-1 gap-6">
            <FeatureCard 
              title="Personalization"
              description="We customize everything to match your website's design and theme seamlessly."
            />
          </div>
        </div>
      </div>
    </div>
  );
}