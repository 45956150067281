import { Hero } from "@/components/ui/animated-hero";
import { BackgroundPaths } from "@/components/ui/background-paths";
import { VoicePricingSection } from "@/components/ui/voice-pricing-section";
import { FeatureCards } from "@/components/ui/feature-cards";
import { BeamsBackground } from "@/components/ui/beams-background";
import { FaqSection } from "@/components/ui/faq-section";
import { useNavigate } from 'react-router-dom';
import { useLanguage } from "@/context/LanguageContext";
import { SEO } from "@/components/SEO";

export default function VoiceAgentPage() {
  const navigate = useNavigate();
  const { t } = useLanguage();

  return (
    <>
      <SEO 
        title="AI Voice Agent Solutions | Serfeo | Natural Conversations"
        description="Transform your phone support with Serfeo's AI-powered voice agent solutions. Handle calls, answer questions, and schedule appointments 24/7."
        schemaType="FAQPage"
        schemaData={{
          mainEntity: t('faq.voice.items').map((item: any) => ({
            '@type': 'Question',
            name: item.question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: item.answer
            }
          }))
        }}
        canonicalOverride="https://serfeo.com/voice-agent"
      />
      <div className="bg-black min-h-screen">
        <BeamsBackground intensity="medium">
          <Hero
            titles={[t('voiceAgentPage.hero.titles.0'), t('voiceAgentPage.hero.titles.1'), t('voiceAgentPage.hero.titles.2')]}
            subtitle={t('voiceAgentPage.hero.subtitle')}
            ctaText={t('voiceAgentPage.hero.ctaText')}
            onCtaClick={() => navigate('/getstarted')}
            showBackButton={true}
            useAuroraBackground={false}
            makeEveryText={t('hero.makeEvery')}
          />
        </BeamsBackground>
        
        {/* Feature cards section */}
        <FeatureCards type="voice" />
        
        <div className="pt-4">
          <h2 className="text-3xl font-bold text-center mb-2">{t('voiceAgentPage.pricingPlans')}</h2>
          <VoicePricingSection />
        </div>
        
        {/* FAQ Section - removed contactInfo prop to hide the More Questions card */}
        <FaqSection
          title={t('faq.title')}
          description={t('faq.description')}
          items={t('faq.voice.items')}
        />
        
        {/* BackgroundPaths moved to bottom, just above footer */}
        <BackgroundPaths
          title={t('voiceAgentPage.revolutionary')}
          description={t('voiceAgentPage.description')}
          buttonText={t('cta.button')}
          onButtonClick={() => navigate('/getstarted')}
        />
      </div>
    </>
  );
}