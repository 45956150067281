import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '@/context/LanguageContext';

export function BackButton() {
  const navigate = useNavigate();
  const { t } = useLanguage();
  
  return (
    <button 
      className="flex items-center gap-2 bg-black border border-gray-800 rounded-md px-4 py-2 text-white hover:bg-gray-900 transition-colors"
      onClick={() => navigate(-1)}
    >
      <ArrowLeft className="h-4 w-4" />
      {t('backButton')}
    </button>
  );
}