import { useLanguage } from "@/context/LanguageContext";
import { SEO } from "@/components/SEO";

export default function PrivacyPolicyPage() {
  const { t } = useLanguage();

  return (
    <>
      <SEO 
        title="Privacy Policy | Serfeo | AI Automation Solutions"
        description="Read Serfeo's privacy policy to understand how we collect, use, and protect your information."
        schemaType="BreadcrumbList"
        canonicalOverride="https://serfeo.com/privacy-policy"
      />
      <div className="bg-black min-h-screen py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold mb-8 text-white">Privacy Policy</h1>
            <div className="prose prose-invert">
              <p className="text-gray-300 mb-6">
                Last updated: 12/03/2025
              </p>
              <div className="space-y-6 text-gray-300">
                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">Introduction</h2>
                  <p>
                    Welcome to Serfeo. We are an AI automation company that provides AI website chatbots and other AI solutions for businesses. This privacy policy explains how we collect, use, and protect personal data.
                  </p>
                  <p className="mt-4">
                    By using our website or any of our AI automation services, you agree to this privacy policy.
                  </p>
                </section>
                
                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">What Data Do We Collect?</h2>
                  <p>
                    We may collect the following personal data:
                  </p>
                  <ul className="list-disc ml-6 mt-2 space-y-1">
                    <li>Identification data: Name, email address, phone number</li>
                    <li>Business data: Company name, company address</li>
                    <li>Communication data: Messages and inquiries sent via our website or AI chatbots</li>
                    <li>Technical data: IP address, browser type, device details, and cookies</li>
                  </ul>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">How Do We Collect Data?</h2>
                  <p>
                    We collect data in the following ways:
                  </p>
                  <ul className="list-disc ml-6 mt-2 space-y-1">
                    <li>When you contact us via our contact form or chatbot</li>
                    <li>When you subscribe to our services or newsletter</li>
                    <li>Through cookies and tracking technologies on our website</li>
                  </ul>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">Why Do We Collect This Data?</h2>
                  <p>
                    We use your data for the following purposes:
                  </p>
                  <ul className="list-disc ml-6 mt-2 space-y-1">
                    <li>To provide and improve our AI services</li>
                    <li>To contact you and offer support</li>
                    <li>For billing and administration</li>
                    <li>For marketing and analytics tools (with your consent)</li>
                  </ul>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">Do We Share Your Data with Third Parties?</h2>
                  <p>
                    We only share personal data with third parties when necessary for our services, such as:
                  </p>
                  <ul className="list-disc ml-6 mt-2 space-y-1">
                    <li>Hosting providers and IT service providers</li>
                    <li>Payment processors for billing</li>
                    <li>Government authorities if legally required</li>
                  </ul>
                  <p className="mt-4">
                    We do not sell personal data to third parties.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">How Do We Protect Your Data?</h2>
                  <p>
                    We take the necessary technical and organizational measures to protect your personal data against loss, misuse, or unauthorized access.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">How Long Do We Store Your Data?</h2>
                  <p>
                    We do not store your data longer than necessary for the purposes for which it was collected, unless we are legally required to keep it longer.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">Your Rights</h2>
                  <p>
                    You have the right to:
                  </p>
                  <ul className="list-disc ml-6 mt-2 space-y-1">
                    <li>Request access to the data we have about you</li>
                    <li>Have your data corrected or deleted</li>
                    <li>Object to the use of your data for marketing purposes</li>
                    <li>Withdraw your consent (if applicable)</li>
                  </ul>
                  <p className="mt-4">
                    To exercise your rights, you can contact us at <a href="mailto:serfeo28@gmail.com" className="text-blue-400 hover:text-blue-300">serfeo28@gmail.com</a>
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">Cookies</h2>
                  <p>
                    Our website uses cookies to enhance the user experience. You can manage cookies through your browser settings.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">Changes to This Privacy Policy</h2>
                  <p>
                    We may update this privacy policy from time to time. We recommend checking this page regularly for any changes.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">Contact</h2>
                  <p>
                    If you have any questions about this privacy policy, please contact us at:
                  </p>
                  <p className="mt-4">
                    Serfeo<br />
                    Email: <a href="mailto:serfeo28@gmail.com" className="text-blue-400 hover:text-blue-300">serfeo28@gmail.com</a><br />
                    Address: Buitenland 35, 2880 Bornem
                  </p>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}