import { useState, useRef, useEffect } from 'react';
import { Globe, ChevronDown } from 'lucide-react';
import { useLanguage } from '../context/LanguageContext';

export const LanguageSwitcher = () => {
  const { language, setLanguage } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (lang: 'EN' | 'NE') => {
    setLanguage(lang);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="flex items-center justify-center h-9 px-3 rounded-lg border border-gray-700 hover:border-blue-500/30 hover:bg-blue-900/10 text-gray-300 hover:text-blue-400 transition-all"
        onClick={toggleDropdown}
      >
        <Globe className="w-4 h-4 mr-1" />
        <span className="text-sm">{language}</span>
        <ChevronDown className="w-3 h-3 ml-1" />
      </button>
      
      {isOpen && (
        <div className="absolute right-0 mt-2 w-24 rounded-md shadow-lg backdrop-blur-md bg-transparent border border-gray-800/20">
          <div className="py-1" role="menu">
            <button
              className={`block w-full text-left px-4 py-2 text-sm hover:bg-gray-800/50 ${language === 'EN' ? 'text-blue-400' : 'text-white'}`}
              onClick={() => changeLanguage('EN')}
            >
              English
            </button>
            <button
              className={`block w-full text-left px-4 py-2 text-sm hover:bg-gray-800/50 ${language === 'NE' ? 'text-blue-400' : 'text-white'}`}
              onClick={() => changeLanguage('NE')}
            >
              Nederlands
            </button>
          </div>
        </div>
      )}
    </div>
  );
};