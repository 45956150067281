import { Hero } from "@/components/ui/animated-hero";
import { BackgroundPaths } from "@/components/ui/background-paths";
import { BeamsBackground } from "@/components/ui/beams-background";
import { useNavigate } from 'react-router-dom';
import { useLanguage } from "@/context/LanguageContext";
import { HotelFeatures } from "@/components/ui/hotel-features";
import { HotelSolutionCardsSection } from "@/components/ui/hotel-solution-cards";
import { SEO } from "@/components/SEO";

export default function HotelsPage() {
  const navigate = useNavigate();
  const { t } = useLanguage();

  return (
    <>
      <SEO 
        title="AI Solutions for Hotels | Serfeo | Belgium"
        description="Streamline check-ins, concierge services, and guest support for your hotel with Serfeo's AI-powered solutions."
        schemaType="LocalBusiness"
        schemaData={{
          name: "Serfeo - Hotel AI Solutions",
          description: "AI automation for hotels: efficient check-ins, guest assistance, and concierge service",
          makesOffer: {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "AI Chatbot for Hotels",
              description: "AI-powered chatbot solutions for hotel operations"
            }
          }
        }}
        canonicalOverride="https://serfeo.com/hotels"
      />
      <div className="bg-black min-h-screen">
        <BeamsBackground intensity="medium">
          <Hero
            titles={[t('hotelsPage.hero.titles.0'), t('hotelsPage.hero.titles.1'), t('hotelsPage.hero.titles.2')]}
            subtitle={t('hotelsPage.hero.subtitle')}
            ctaText={t('hotelsPage.hero.ctaText')}
            onCtaClick={() => navigate('/getstarted')}
            solutionsSectionId="hotel-solutions"
            showSolutionsButton={true}
            useAuroraBackground={false}
            makeEveryText={t('hero.makeEvery')}
          />
        </BeamsBackground>
        
        {/* Empty div to maintain the id for scroll navigation */}
        <div id="hotel-solutions"></div>
        
        {/* Features section added above pricing */}
        <div className="container mx-auto px-4 py-12">
          <h2 className="text-3xl font-bold text-center mb-8">{t('hotelsPage.features')}</h2>
          <HotelFeatures />
        </div>
        
        {/* Solution Cards Section */}
        <HotelSolutionCardsSection />
        
        {/* BackgroundPaths moved to bottom, just above footer */}
        <BackgroundPaths
          title={t('hotelsPage.revolutionize')}
          description={t('hotelsPage.description')}
          buttonText={t('cta.button')}
          onButtonClick={() => navigate('/getstarted')}
        />
      </div>
    </>
  );
}