import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  article?: boolean;
  schemaType?: 'Organization' | 'LocalBusiness' | 'FAQPage' | 'BreadcrumbList';
  schemaData?: Record<string, any>;
  canonicalOverride?: string; // Optional override for canonical URL
}

const defaultTitle = 'Serfeo | AI Automation | Chatbots | Intelligent Solutions | Belgium';
const defaultDescription = 'Serfeo provides AI automation and chatbot solutions to streamline business operations. Enhance efficiency with smart automation tailored to your needs.';
const defaultImage = '/Assets/Favicon.png';
const siteUrl = 'https://serfeo.com'; // No trailing slash

export const SEO: React.FC<SEOProps> = ({
  title = defaultTitle,
  description = defaultDescription,
  image = defaultImage,
  article = false,
  schemaType = 'Organization',
  schemaData = {},
  canonicalOverride
}) => {
  const { pathname } = useLocation();
  
  // Normalize the pathname to ensure consistent URL formatting
  const normalizedPathname = pathname === '/' ? '' : pathname.replace(/\/$/, '');
  
  // Generate the canonical URL - either use the override or construct from pathname
  const canonicalUrl = canonicalOverride || `${siteUrl}${normalizedPathname}`;
  
  // URLs for OpenGraph and other metadata
  const pageUrl = canonicalUrl;
  const fullImageUrl = image.startsWith('http') ? image : `${siteUrl}${image}`;

  // Basic organization schema
  const organizationSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Serfeo',
    url: siteUrl,
    logo: `${siteUrl}/Assets/Favicon.png`,
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+32468153990',
      contactType: 'customer service',
      availableLanguage: ['English', 'Dutch']
    },
    sameAs: [
      'https://www.instagram.com/serfeoautomations/'
    ]
  };

  // Local business schema
  const localBusinessSchema = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: 'Serfeo',
    image: fullImageUrl,
    url: siteUrl,
    telephone: '+32468153990',
    priceRange: '€€',
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'Belgium'
    },
    openingHoursSpecification: {
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday'
      ],
      opens: '09:00',
      closes: '18:00'
    }
  };

  // FAQ schema - example for the chatbot page
  const faqSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How does the AI chatbot integrate with my existing systems?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Our chatbots are designed to integrate seamlessly with virtually any existing website or platform. We offer custom API integrations and can connect with your CRM, reservation system, inventory management, and more.'
        }
      },
      {
        '@type': 'Question',
        name: 'Can the chatbot handle multiple languages?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, our chatbots support multilingual communication, automatically switching languages to serve customers in their preferred language, providing a more personalized experience.'
        }
      },
      {
        '@type': 'Question',
        name: 'How long does it take to implement the chatbot solution?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Implementation time varies depending on complexity, but typically we can set up and train a fully functional chatbot within 2-4 weeks after our initial consultation.'
        }
      }
    ]
  };

  // Breadcrumb schema - dynamic based on current path
  const generateBreadcrumbSchema = () => {
    const pathSegments = normalizedPathname.split('/').filter(Boolean);
    const breadcrumbItems = [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: siteUrl
      }
    ];

    if (pathSegments.length) {
      pathSegments.forEach((segment, index) => {
        let readableSegment = segment.charAt(0).toUpperCase() + segment.slice(1).replace(/-/g, ' ');
        
        // Special cases for URL segments
        switch(segment) {
          case 'getstarted':
            readableSegment = 'Get Started';
            break;
          case 'voice-agent':
            readableSegment = 'Voice Agent';
            break;
          case 'privacy-policy':
            readableSegment = 'Privacy Policy';
            break;
          case 'terms-of-service':
            readableSegment = 'Terms of Service';
            break;
        }
        
        breadcrumbItems.push({
          '@type': 'ListItem',
          position: index + 2,
          name: readableSegment,
          item: `${siteUrl}/${pathSegments.slice(0, index + 1).join('/')}`
        });
      });
    }

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbItems
    };
  };

  // Select the schema based on the type
  let schemaMarkup;
  switch (schemaType) {
    case 'LocalBusiness':
      schemaMarkup = { ...localBusinessSchema, ...schemaData };
      break;
    case 'FAQPage':
      schemaMarkup = { ...faqSchema, ...schemaData };
      break;
    case 'BreadcrumbList':
      schemaMarkup = generateBreadcrumbSchema();
      break;
    default:
      schemaMarkup = { ...organizationSchema, ...schemaData };
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={fullImageUrl} />
      
      {/* Canonical Link - now using the normalized URL */}
      <link rel="canonical" href={canonicalUrl} />

      {/* Open Graph / Facebook */}
      <meta property="og:url" content={pageUrl} />
      <meta property="og:type" content={article ? 'article' : 'website'} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={fullImageUrl} />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={pageUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={fullImageUrl} />
      
      {/* Schema.org JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify(schemaMarkup)}
      </script>
    </Helmet>
  );
};