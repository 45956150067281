import { BackgroundPaths } from "@/components/ui/background-paths";
import { LeadForm } from "@/components/LeadForm";
import { useNavigate } from 'react-router-dom';
import { useLanguage } from "@/context/LanguageContext";
import { SEO } from "@/components/SEO";

export default function DemoPage() {
  const navigate = useNavigate();
  const { t } = useLanguage();

  return (
    <>
      <SEO 
        title="Request a Demo | Serfeo | AI Automation Solutions"
        description="See Serfeo's AI solutions in action and discover how they can transform your business. Request a demo today."
        schemaType="BreadcrumbList"
        canonicalOverride="https://serfeo.com/demo"
      />
      <div className="bg-black min-h-screen py-20">
        <div className="container mx-auto px-4">
          <BackgroundPaths
            title={t('demoPage.title')}
            description={t('demoPage.description')}
            buttonText={t('demoPage.startButton')}
            onButtonClick={() => navigate('/getstarted')}
          />
          <div className="max-w-2xl mx-auto mt-20">
            <LeadForm source="demo" />
          </div>
        </div>
      </div>
    </>
  );
}