import { useLanguage } from "@/context/LanguageContext";
import { SEO } from "@/components/SEO";

export default function TermsOfServicePage() {
  const { t } = useLanguage();

  return (
    <>
      <SEO 
        title="Terms of Service | Serfeo | AI Automation Solutions"
        description="Read Serfeo's terms of service to understand the conditions for using our AI automation solutions."
        schemaType="BreadcrumbList"
        canonicalOverride="https://serfeo.com/terms-of-service"
      />
      <div className="bg-black min-h-screen py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold mb-8 text-white">Terms of Service</h1>
            <div className="prose prose-invert">
              <p className="text-gray-300 mb-6">
                Last updated: 12/03/2025
              </p>
              <div className="space-y-6 text-gray-300">
                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">1. Introduction</h2>
                  <p>
                    Welcome to Serfeo. We provide AI automation solutions, such as AI website chatbots and other AI automations, for businesses.
                  </p>
                  <p className="mt-4">
                    By using our services and website, you agree to these Terms of Service. If you do not agree, you should not use our services.
                  </p>
                </section>
                
                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">2. Services</h2>
                  <p>
                    We offer various AI automation services, including:
                  </p>
                  <ul className="list-disc ml-6 mt-2 space-y-1">
                    <li>AI website chatbots</li>
                    <li>AI customer service solutions</li>
                    <li>AI-based business automation</li>
                  </ul>
                  <p className="mt-4">
                    We reserve the right to modify or discontinue our services at any time.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">3. User Conditions</h2>
                  <p>
                    By using our services, you confirm that:
                  </p>
                  <ul className="list-disc ml-6 mt-2 space-y-1">
                    <li>You are at least 18 years old or have parental/guardian consent</li>
                    <li>You provide accurate and complete information when creating an account or using our services</li>
                    <li>You do not use our AI solutions for illegal or harmful purposes</li>
                  </ul>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">4. Pricing and Payments</h2>
                  <p>
                    Our services may include a one-time setup fee and/or monthly maintenance costs.<br />
                    Payments must be made within the agreed timeframe.<br />
                    If payments are overdue, we reserve the right to suspend or terminate the service.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">5. Intellectual Property</h2>
                  <p>
                    We retain all rights to the software, technology, and AI solutions we offer.<br />
                    You may not copy, reproduce, or resell our services without our written permission.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">6. Service Termination</h2>
                  <p>
                    We may terminate your access to our services if you:
                  </p>
                  <ul className="list-disc ml-6 mt-2 space-y-1">
                    <li>Violate these Terms of Service</li>
                    <li>Misuse our AI automations</li>
                    <li>Fail to meet payment obligations</li>
                  </ul>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">7. Limitation of Liability</h2>
                  <p>
                    We are not liable for:
                  </p>
                  <ul className="list-disc ml-6 mt-2 space-y-1">
                    <li>Damage caused by the use of our AI solutions</li>
                    <li>Indirect, incidental, or consequential damages</li>
                    <li>Errors or inaccuracies in AI-generated responses</li>
                  </ul>
                  <p className="mt-4">
                    Our AI systems are designed as tools and may contain human-like errors. It is the user's responsibility to verify information.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">8. Privacy and Data Protection</h2>
                  <p>
                    We collect and process personal data in accordance with our <a href="/privacy-policy" className="text-blue-400 hover:text-blue-300">Privacy Policy</a>.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">9. Changes to the Terms of Service</h2>
                  <p>
                    We may update these Terms of Service at any time. If significant changes occur, we will notify you via email or our website.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">10. Governing Law</h2>
                  <p>
                    These Terms of Service are governed by the laws of Belgium. Any disputes will be handled by the competent court in Belgium.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-white mb-4">11. Contact Information</h2>
                  <p>
                    If you have any questions about these Terms of Service, please contact us:
                  </p>
                  <p className="mt-4">
                    Serfeo<br />
                    Email: <a href="mailto:serfeo28@gmail.com" className="text-blue-400 hover:text-blue-300">serfeo28@gmail.com</a><br />
                    Address: Buitenland 35, 2880 Bornem
                  </p>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}