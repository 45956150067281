import { motion } from "framer-motion"
import { Check, X } from "lucide-react"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import { useNavigate } from "react-router-dom"

interface BenefitProps {
  text: string
  checked: boolean
}

const Benefit = ({ text, checked }: BenefitProps) => {
  return (
    <div className="flex items-center gap-3">
      {checked ? (
        <span className="grid size-4 place-content-center rounded-full bg-blue-500 text-sm text-white">
          <Check className="size-3" />
        </span>
      ) : (
        <span className="grid size-4 place-content-center rounded-full bg-gray-800 text-sm text-gray-400">
          <X className="size-3" />
        </span>
      )}
      <span className="text-sm text-gray-300">{text}</span>
    </div>
  )
}

interface PricingCardProps {
  tier: string
  price: string
  monthlyPrice?: string
  bestFor: string
  CTA: string
  benefits: Array<{ text: string; checked: boolean }>
  className?: string
}

export const PricingCard = ({
  tier,
  price,
  monthlyPrice,
  bestFor,
  CTA,
  benefits,
  className,
}: PricingCardProps) => {
  const navigate = useNavigate();
  
  const handleButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate('/getstarted');
  };
  
  return (
    <motion.div
      initial={{ filter: "blur(2px)" }}
      whileInView={{ filter: "blur(0px)" }}
      transition={{ duration: 0.5, ease: "easeInOut", delay: 0.25 }}
      className="h-full"
    >
      <Card
        className={cn(
          "relative h-full w-full overflow-hidden border flex flex-col",
          "border-gray-800 bg-gradient-to-br from-gray-900/50 to-gray-800/80",
          "p-6",
          "shadow-xl shadow-blue-900/5 hover:shadow-blue-900/10 transition-shadow",
          tier === "Pro" && "border-blue-900/30 from-blue-950/30 to-gray-900/90",
          className,
        )}
      >
        {/* Blue accent glow for Pro tier */}
        {tier === "Pro" && (
          <div className="absolute -inset-0.5 bg-blue-500/10 blur-sm opacity-50"></div>
        )}

        <div className="flex flex-col items-center border-b pb-6 border-gray-800 min-h-[160px] justify-center">
          <span className="mb-6 inline-block text-white">
            {tier}
          </span>
          <div className="flex flex-col items-center gap-2 mb-4">
            <span className="inline-block text-4xl font-medium text-white">
              {price}
            </span>
            {monthlyPrice && (
              <span className="inline-block text-2xl font-medium text-gray-400">
                {monthlyPrice}
              </span>
            )}
            {/* Add placeholder for enterprise tier to maintain consistent spacing */}
            {!monthlyPrice && tier === "Enterprise" && (
              <span className="inline-block h-8"></span>
            )}
          </div>
          <span className={cn(
            "bg-gradient-to-br from-gray-200 to-gray-400 bg-clip-text text-center text-transparent",
            tier === "Pro" && "from-blue-300 to-white"
          )}>
            {bestFor}
          </span>
        </div>
        
        {/* Fixed height container for benefits */}
        <div className="space-y-4 py-9 flex-grow overflow-y-auto min-h-[430px]">
          {benefits.map((benefit, index) => (
            <Benefit key={index} {...benefit} />
          ))}
        </div>
        
        {/* Footer area with button */}
        <div className="mt-auto pt-4">
          <Button
            className="w-full z-10 relative"
            variant="default"
            onClick={handleButtonClick}
          >
            {CTA}
          </Button>
        </div>
      </Card>
    </motion.div>
  )
}