// Define the type for the translations object
type TranslationsType = {
  [key: string]: {
    [key: string]: any;
  };
};

// Create the translations object with English and Dutch translations
export const translations: TranslationsType = {
  EN: {
    nav: {
      home: 'Home',
      about: 'About',
      industries: 'Industries',
      contact: 'Contact',
      getStarted: 'Get Started'
    },
    industries: {
      restaurants: 'Restaurants',
      hotels: 'Hotels',
      dealerships: 'Car Dealerships'
    },
    footer: {
      tagline: 'AI-Powered Solutions to Streamline Your Business Operations',
      company: 'Company',
      legal: 'Legal',
      connect: 'Connect',
      privacyPolicy: 'Privacy Policy',
      termsOfService: 'Terms of Service',
      copyright: '© 2025 Serfeo. All rights reserved.'
    },
    hero: {
      makeEvery: 'Make every interaction',
      moreEfficient: 'More Efficient',
      morePersonal: 'More Personal',
      moreProfitable: 'More Profitable',
      subtitle: 'Intelligent automation solutions powered by AI for restaurants, hotels, and car dealerships',
      ctaText: 'Get Started',
      solutions: 'View Solutions'
    },
    testimonials: {
      title: 'Trusted by businesses everywhere',
      description: 'See what our clients have to say about our AI solutions'
    },
    leadForm: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Business Email',
      company: 'Company Name',
      phone: 'Phone',
      message: 'Message',
      messagePlaceholder: 'Tell us about your business needs...',
      getStarted: 'Get Started',
      submitting: 'Submitting...',
      success: 'Thank you! We\'ll be in touch soon.'
    },
    cta: {
      title: 'Ready to transform your business?',
      description: 'Get in touch with our team and discover how our AI solutions can help you drive efficiency and growth.',
      button: 'Contact Us Today'
    },
    industries_section: {
      title: 'Industries We Serve',
      restaurants: {
        title: 'Restaurants',
        description: 'Automate reservations, orders, and customer support to enhance dining experiences.'
      },
      hotels: {
        title: 'Hotels',
        description: 'Streamline check-ins, concierge services, and guest support for superior hospitality.'
      },
      dealerships: {
        title: 'Car Dealerships',
        description: 'Optimize customer inquiries, appointment scheduling, and follow-ups to drive sales.'
      }
    },
    backButton: 'Back',
    timeline: {
      title: '24/7 AI Assistance',
      content: 'Our AI solutions operate around the clock, ensuring your customers receive immediate assistance whenever they need it, without delays or wait times.',
      multilingual: {
        title: 'Multilingual Support',
        content: 'Break language barriers with our AI solutions that communicate fluently in multiple languages, making your business accessible to a global audience.'
      },
      costReduction: {
        title: 'Cost Reduction',
        content: 'Reduce operational costs by automating routine tasks and inquiries, allowing your team to focus on complex customer needs and strategic initiatives.'
      },
      integration: {
        title: 'Seamless Integration',
        content: 'Our solutions integrate effortlessly with your existing systems and workflows, ensuring a smooth transition and immediate improvement in operational efficiency.'
      }
    },
    aboutPage: {
      mission: {
        title: 'Our Mission',
        description: 'At Serfeo, we\'re dedicated to revolutionizing business operations through cutting-edge AI automation. We believe that intelligent technology should be accessible to businesses of all sizes, enabling them to deliver exceptional service while optimizing their resources.'
      },
      approach: {
        title: 'Our Approach',
        description: 'We combine deep industry knowledge with advanced AI capabilities to create solutions that address the unique challenges of restaurants, hotels, and car dealerships. Our team of experts works closely with each client to ensure our solutions align perfectly with their specific business goals and operational needs.'
      },
      whyChoose: 'Why Choose Serfeo'
    },
    contactPage: {
      getInTouch: 'Get In Touch',
      description: 'Have questions or ready to explore how our AI solutions can transform your business? Reach out to our team.',
      email: 'Email',
      phone: 'Phone',
      workingHours: 'Working Hours',
      workingHoursValue: 'Monday to Friday: 9:00 AM - 6:00 PM CET',
      workingHoursNote: 'We typically respond within 24 hours',
      sendMessage: 'Send us a message'
    },
    getStartedPage: {
      title: 'Start Your AI Journey',
      description: 'Fill out the form below to get started with our AI solutions tailored to your business needs.',
      howItWorks: 'How It Works',
      steps: {
        consultation: {
          title: 'Consultation',
          description: 'We begin with a thorough consultation to understand your business, challenges, and goals.'
        },
        customization: {
          title: 'Customization',
          description: 'Our team develops a tailored solution designed specifically for your unique business needs.'
        },
        implementation: {
          title: 'Implementation',
          description: 'We seamlessly integrate our AI solutions with your existing systems and provide comprehensive training.'
        }
      }
    },
    restaurantsPage: {
      hero: {
        titles: ['A Reservation', 'Menu Navigation', 'Instant Support'],
        subtitle: 'AI-powered chatbots and voice agents designed specifically for restaurants',
        ctaText: 'Elevate Your Restaurant'
      },
      features: 'Key Features for Restaurants',
      elevate: 'Elevate Your Restaurant Experience',
      description: 'From reservations to menu questions, our AI solutions handle it all so your staff can focus on creating exceptional dining experiences.'
    },
    restaurantFeatures: {
      customerSupport: {
        title: '24/7 Customer Support',
        description: 'Provide round-the-clock assistance to your customers, answering questions and resolving issues instantly.'
      },
      satisfaction: {
        title: 'Enhanced Satisfaction',
        description: 'Improve customer satisfaction with quick, consistent, and helpful responses to all inquiries.'
      }
    },
    restaurantSolutions: {
      chatbot: {
        title: 'AI Chatbot for Restaurants',
        description: 'An intelligent chatbot that handles reservations, menu inquiries, and customer support seamlessly.',
        features: {
          support: '24/7 customer support',
          menu: 'Detailed menu information',
          allergen: 'Allergen information',
          multilingual: 'Multilingual capabilities',
          reservation: 'Reservation management'
        }
      },
      voice: {
        title: 'Voice Agent for Restaurants',
        description: 'A sophisticated voice agent that handles phone calls, reservations, and inquiries with natural conversation.',
        features: {
          support: 'Natural conversation flow',
          menu: 'Menu recommendations',
          faq: 'Common question handling',
          availability: 'Table availability checks',
          reservations: 'Seamless reservation taking'
        }
      }
    },
    hotelsPage: {
      hero: {
        titles: ['Efficient Check-ins', 'Guest Assistance', 'Concierge Service'],
        subtitle: 'AI-powered solutions to enhance guest experiences and streamline hotel operations',
        ctaText: 'Improve Guest Experience'
      },
      features: 'Key Features for Hotels',
      revolutionize: 'Revolutionize Your Hotel Operations',
      description: 'From bookings to guest inquiries, our AI solutions handle it all so your staff can focus on creating memorable stays.'
    },
    hotelFeatures: {
      roomBooking: {
        title: 'Room Booking',
        description: 'Streamline the booking process with AI-powered assistance that handles reservations efficiently.'
      },
      guestExperience: {
        title: 'Guest Experience',
        description: 'Enhance guest satisfaction with immediate responses to inquiries and personalized recommendations.'
      },
      dynamicPricing: {
        title: 'Dynamic Pricing',
        description: 'Optimize room rates based on demand, seasonality, and competition to maximize revenue.'
      },
      virtualConcierge: {
        title: 'Virtual Concierge',
        description: 'Provide guests with 24/7 access to information about hotel amenities, local attractions, and services.'
      },
      multiChannel: {
        title: 'Multi-Channel Support',
        description: 'Engage with guests across all communication channels, from website to messaging apps.'
      },
      customerSupport: {
        title: 'Customer Support',
        description: 'Handle customer inquiries, complaints, and feedback promptly and efficiently.'
      },
      satisfaction: {
        title: 'Satisfaction Surveys',
        description: 'Automatically collect and analyze guest feedback to continuously improve services.'
      },
      loyalty: {
        title: 'Loyalty Programs',
        description: 'Manage and promote loyalty programs to encourage repeat bookings and referrals.'
      }
    },
    hotelSolutions: {
      chatbot: {
        title: 'AI Chatbot for Hotels',
        description: 'An intelligent chatbot that handles bookings, guest inquiries, and service requests seamlessly.',
        features: {
          support: '24/7 guest support',
          reservations: 'Room booking management',
          recommendations: 'Local recommendations',
          multilingual: 'Multilingual capabilities',
          checkin: 'Express check-in assistance'
        }
      },
      voice: {
        title: 'Voice Agent for Hotels',
        description: 'A sophisticated voice agent that handles phone calls, reservations, and guest services with natural conversation.',
        features: {
          support: 'Natural conversation flow',
          concierge: 'Virtual concierge services',
          roomService: 'Room service ordering',
          bookings: 'Seamless room booking',
          local: 'Local area information'
        }
      }
    },
    dealershipsPage: {
      hero: {
        titles: ['High Quality', 'Appointment Setting', 'Lead Generation'],
        subtitle: 'AI-powered solutions to enhance customer engagement and drive sales for car dealerships',
        ctaText: 'Boost Your Dealership'
      },
      features: 'Key Features for Dealerships',
      drive: 'Drive Your Sales Forward',
      description: 'From inventory inquiries to test drive scheduling, our AI solutions handle it all so your team can focus on closing deals.'
    },
    dealershipFeatures: {
      inventory: {
        title: 'Inventory Management',
        description: 'Keep customers informed about your latest vehicles with real-time inventory information.'
      },
      customerExperience: {
        title: 'Customer Experience',
        description: 'Enhance customer satisfaction with immediate responses to inquiries and personalized recommendations.'
      },
      dynamicPricing: {
        title: 'Dynamic Pricing',
        description: 'Provide customers with up-to-date pricing information based on current promotions and financing options.'
      },
      leadGeneration: {
        title: 'Lead Generation',
        description: 'Capture and qualify leads 24/7, ensuring no potential customer slips through the cracks.'
      },
      multiChannel: {
        title: 'Multi-Channel Support',
        description: 'Engage with customers across all communication channels, from website to messaging apps.'
      },
      customerSupport: {
        title: 'Customer Support',
        description: 'Handle customer inquiries, complaints, and feedback promptly and efficiently.'
      },
      satisfaction: {
        title: 'Satisfaction Surveys',
        description: 'Automatically collect and analyze customer feedback to continuously improve services.'
      },
      followUp: {
        title: 'Automated Follow-ups',
        description: 'Maintain customer relationships with timely, personalized follow-up communications.'
      }
    },
    dealershipSolutions: {
      chatbot: {
        title: 'AI Chatbot for Dealerships',
        description: 'An intelligent chatbot that handles inventory inquiries, financing questions, and appointment scheduling seamlessly.',
        features: {
          support: '24/7 customer support',
          inventory: 'Real-time inventory search',
          financing: 'Financing options explanation',
          multilingual: 'Multilingual capabilities',
          scheduling: 'Test drive scheduling'
        }
      },
      voice: {
        title: 'Voice Agent for Dealerships',
        description: 'A sophisticated voice agent that handles phone calls, appointments, and customer inquiries with natural conversation.',
        features: {
          support: 'Natural conversation flow',
          leads: 'Lead qualification',
          appointments: 'Appointment scheduling',
          follow: 'Automated follow-ups',
          service: 'Service department connection'
        }
      }
    },
    chatbotPage: {
      hero: {
        subtitle: 'Transform your customer service with our AI-powered chatbot solutions',
        ctaText: 'Get Started'
      },
      features: 'Key Features',
      pricingPlans: 'Pricing Plans',
      nextGen: 'Next-Generation Customer Support',
      description: 'Our chatbots handle customer inquiries, provide information, and perform tasks 24/7, freeing your team to focus on what matters most.'
    },
    voiceAgentPage: {
      hero: {
        titles: ['Answer Calls', 'Book Appointments', 'Qualify Leads'],
        subtitle: 'Transform your phone support with our AI-powered voice agent solutions',
        ctaText: 'Get Started'
      },
      pricingPlans: 'Pricing Plans',
      revolutionary: 'Revolutionary Voice AI Technology',
      description: 'Our voice agents handle phone calls, answer questions, and schedule appointments with natural, human-like conversation.'
    },
    featuresSection: {
      voice: {
        support: {
          title: '24/7 Call Support',
          description: 'Our voice agents answer calls around the clock, ensuring you never miss a customer inquiry or opportunity.'
        },
        natural: {
          title: 'Natural Conversation',
          description: 'Advanced AI technology enables human-like conversations that feel natural and engaging to callers.'
        },
        transcription: {
          title: 'Call Transcription',
          description: 'Automatically transcribe and analyze calls for valuable insights and quality assurance.'
        },
        multilingual: {
          title: 'Multilingual Support',
          description: 'Our voice agents speak multiple languages, allowing you to serve a diverse customer base.'
        },
        integration: {
          title: 'CRM Integration',
          description: 'Seamlessly integrate with your existing CRM system to track customer interactions and follow up on leads.'
        }
      }
    },
    solutions: {
      title: 'Our Solutions',
      chatbot: {
        title: 'AI Chatbot',
        description: 'An intelligent chatbot that handles customer inquiries, provides information, and performs tasks 24/7.',
        features: {
          support: '24/7 customer support',
          menu: 'Product/service information',
          allergen: 'FAQ handling',
          multilingual: 'Multilingual capabilities',
          reservation: 'Appointment scheduling'
        }
      },
      voice: {
        title: 'AI Voice Agent',
        description: 'A sophisticated voice agent that handles phone calls, answers questions, and schedules appointments with natural conversation.',
        features: {
          support: 'Natural conversation flow',
          menu: 'Information delivery',
          faq: 'Common question handling',
          availability: 'Availability checks',
          reservations: 'Appointment booking'
        }
      }
    },
    pricing: {
      title: 'Simple, Transparent Pricing',
      subtitle: 'Choose the plan that fits your business needs',
      benefits: {
        support: '24/7 Support',
        basicMenu: 'Basic Menu Integration',
        advancedMenu: 'Advanced Menu Integration',
        fullSystem: 'Full System Integration',
        conversations: 'Up to 500 conversations',
        unlimited: 'Unlimited conversations',
        email: 'Email Support',
        priority: 'Priority Support',
        prioritySupport: '24/7 Priority Support',
        custom: 'Custom Branding',
        analytics: 'Advanced Analytics',
        callHandling: 'Basic Call Handling',
        calls: 'Up to 100 calls/mo',
        callsAdvanced: 'Up to 500 calls/mo',
        unlimitedCalls: 'Unlimited calls',
        voiceTraining: 'Voice Training',
        callAnalytics: 'Call Analytics',
        basicAnalytics: 'Basic Analytics',
        advancedCall: 'Advanced Call Handling',
        callCenter: 'Full Call Center Integration'
      },
      chatbot: {
        starter: {
          tier: 'Starter',
          price: '€400',
          monthlyPrice: '€40/mo',
          bestFor: 'Best for small restaurants',
          cta: 'Start Free Trial'
        },
        pro: {
          tier: 'Pro',
          price: '€600',
          monthlyPrice: '€60/mo',
          bestFor: 'Best for scaling restaurants',
          cta: 'Start Free Trial'
        },
        enterprise: {
          tier: 'Enterprise',
          price: 'Custom',
          bestFor: 'Best for bigger restaurants',
          cta: 'Contact Sales'
        }
      },
      voice: {
        starter: {
          tier: 'Starter',
          price: '€499',
          monthlyPrice: '€79/mo',
          bestFor: 'Best for small businesses',
          cta: 'Start Free Trial'
        },
        pro: {
          tier: 'Pro',
          price: '€999',
          monthlyPrice: '€149/mo',
          bestFor: 'Best for growing businesses',
          cta: 'Start Free Trial'
        },
        enterprise: {
          tier: 'Enterprise',
          price: 'Custom',
          bestFor: 'Best for large operations',
          cta: 'Contact Sales'
        }
      }
    },
    faq: {
      title: 'Frequently Asked Questions',
      description: 'Find answers to common questions about our AI solutions',
      voice: {
        items: [
          {
            question: 'How does the voice agent handle complex inquiries?',
            answer: 'Our voice agents are designed to handle a wide range of inquiries. For complex scenarios, they can seamlessly transfer to a human agent when necessary, ensuring your customers always receive the support they need.'
          },
          {
            question: 'What languages are supported by the voice agent?',
            answer: 'Our voice agents support multiple languages including English, Dutch, French, German, and Spanish. We can add additional languages based on your specific requirements.'
          },
          {
            question: 'How secure are conversations with the voice agent?',
            answer: 'Security is a top priority. All conversations are encrypted, and we comply with relevant data protection regulations including GDPR. We do not store sensitive customer information unless explicitly authorized.'
          },
          {
            question: 'Can the voice agent integrate with our existing phone system?',
            answer: 'Yes, our voice agents are designed to integrate seamlessly with most modern phone systems and VoIP providers. Our team will work with you to ensure a smooth integration process.'
          },
          {
            question: 'How long does it take to deploy a voice agent?',
            answer: 'Typically, we can deploy a fully functional voice agent within 2-4 weeks after our initial consultation. This includes customization, training, and integration with your existing systems.'
          }
        ]
      }
    },
    privacyPolicy: {
      title: 'Privacy Policy',
      lastUpdated: 'Last Updated: April 1, 2025',
      sections: {
        collect: {
          title: 'Information We Collect',
          content: 'We collect information you provide directly to us when you fill out forms on our website, communicate with us via email or phone, or interact with our AI solutions. This may include your name, email address, phone number, company name, and any other information you choose to provide.'
        },
        use: {
          title: 'How We Use Your Information',
          content: 'We use the information we collect to provide, maintain, and improve our services, to communicate with you, to respond to your inquiries, and to customize and enhance your experience with our AI solutions.'
        },
        sharing: {
          title: 'Information Sharing',
          content: 'We may share your information with third-party service providers who perform services on our behalf, such as hosting, data analysis, payment processing, and customer service. We may also share your information as required by law or to protect our rights and interests.'
        },
        security: {
          title: 'Security',
          content: 'We take reasonable measures to help protect your personal information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction.'
        }
      }
    },
    termsOfService: {
      title: 'Terms of Service',
      lastUpdated: 'Last Updated: April 1, 2025',
      sections: {
        acceptance: {
          title: 'Acceptance of Terms',
          content: 'By accessing or using our website and services, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.'
        },
        license: {
          title: 'License and Usage',
          content: 'We grant you a limited, non-exclusive, non-transferable, and revocable license to use our services for your business purposes. You may not use our services for any illegal or unauthorized purpose.'
        },
        modifications: {
          title: 'Modifications to Service',
          content: 'We reserve the right to modify or discontinue, temporarily or permanently, our services with or without notice. We shall not be liable to you or any third party for any modification, suspension, or discontinuance of the service.'
        },
        liability: {
          title: 'Limitation of Liability',
          content: 'In no event shall Serfeo be liable for any indirect, incidental, special, consequential, or punitive damages, including loss of profits, data, or other intangible losses, resulting from your use of our services.'
        }
      }
    },
    demoPage: {
      title: 'Request a Demo',
      description: 'See our AI solutions in action and discover how they can transform your business',
      startButton: 'Start Free Trial'
    }
  },
  NE: {
    nav: {
      home: 'Home',
      about: 'Over Ons',
      industries: 'Industrieën',
      contact: 'Contact',
      getStarted: 'Aan de slag'
    },
    industries: {
      restaurants: 'Restaurants',
      hotels: 'Hotels',
      dealerships: 'Autodealers'
    },
    footer: {
      tagline: 'Door AI aangestuurde oplossingen om uw bedrijfsactiviteiten te stroomlijnen',
      company: 'Bedrijf',
      legal: 'Juridisch',
      connect: 'Verbinden',
      privacyPolicy: 'Privacybeleid',
      termsOfService: 'Servicevoorwaarden',
      copyright: '© 2025 Serfeo. Alle rechten voorbehouden.'
    },
    hero: {
      makeEvery: 'Maak elke interactie',
      moreEfficient: 'Efficiënter',
      morePersonal: 'Persoonlijker',
      moreProfitable: 'Winstgevender',
      subtitle: 'Intelligente automatiseringsoplossingen aangedreven door AI voor restaurants, hotels en autodealers',
      ctaText: 'Aan de slag',
      solutions: 'Bekijk Oplossingen'
    },
    testimonials: {
      title: 'Vertrouwd door bedrijven overal',
      description: 'Zie wat onze klanten te zeggen hebben over onze AI-oplossingen'
    },
    leadForm: {
      firstName: 'Voornaam',
      lastName: 'Achternaam',
      email: 'Zakelijke e-mail',
      company: 'Bedrijfsnaam',
      phone: 'Telefoon',
      message: 'Bericht',
      messagePlaceholder: 'Vertel ons over uw zakelijke behoeften...',
      getStarted: 'Aan de slag',
      submitting: 'Indienen...',
      success: 'Dank je! We nemen binnenkort contact met je op.'
    },
    cta: {
      title: 'Klaar om uw bedrijf te transformeren?',
      description: 'Neem contact op met ons team en ontdek hoe onze AI-oplossingen u kunnen helpen efficiëntie en groei te stimuleren.',
      button: 'Neem vandaag contact op'
    },
    industries_section: {
      title: 'Industrieën die wij bedienen',
      restaurants: {
        title: 'Restaurants',
        description: 'Automatiseer reserveringen, bestellingen en klantenondersteuning om de eetervaring te verbeteren.'
      },
      hotels: {
        title: 'Hotels',
        description: 'Stroomlijn check-ins, conciërgediensten en gasteondersteuning voor superieure gastvrijheid.'
      },
      dealerships: {
        title: 'Autodealers',
        description: 'Optimaliseer klantaanvragen, afsprakenplanning en follow-ups om verkopen te stimuleren.'
      }
    },
    backButton: 'Terug',
    timeline: {
      title: '24/7 AI Assistentie',
      content: 'Onze AI-oplossingen werken de klok rond, zodat uw klanten onmiddellijke hulp krijgen wanneer ze die nodig hebben, zonder vertragingen of wachttijden.',
      multilingual: {
        title: 'Meertalige Ondersteuning',
        content: 'Doorbreek taalbarrières met onze AI-oplossingen die vloeiend communiceren in meerdere talen, waardoor uw bedrijf toegankelijk wordt voor een wereldwijd publiek.'
      },
      costReduction: {
        title: 'Kostenreductie',
        content: 'Verminder operationele kosten door routinetaken en vragen te automatiseren, zodat uw team zich kan concentreren op complexe klantbehoeften en strategische initiatieven.'
      },
      integration: {
        title: 'Naadloze Integratie',
        content: 'Onze oplossingen integreren moeiteloos met uw bestaande systemen en workflows, waardoor een soepele overgang en onmiddellijke verbetering van de operationele efficiëntie wordt gegarandeerd.'
      }
    },
    aboutPage: {
      mission: {
        title: 'Onze Missie',
        description: 'Bij Serfeo zijn we toegewijd aan het revolutioneren van bedrijfsoperaties door middel van geavanceerde AI-automatisering. Wij geloven dat intelligente technologie toegankelijk moet zijn voor bedrijven van alle groottes, zodat ze uitzonderlijke service kunnen leveren terwijl ze hun middelen optimaliseren.'
      },
      approach: {
        title: 'Onze Aanpak',
        description: 'We combineren diepgaande industriekennis met geavanceerde AI-mogelijkheden om oplossingen te creëren die de unieke uitdagingen van restaurants, hotels en autodealers aanpakken. Ons team van experts werkt nauw samen met elke klant om ervoor te zorgen dat onze oplossingen perfect aansluiten bij hun specifieke bedrijfsdoelen en operationele behoeften.'
      },
      whyChoose: 'Waarom Kiezen voor Serfeo'
    },
    contactPage: {
      getInTouch: 'Neem Contact Op',
      description: 'Heeft u vragen of bent u klaar om te ontdekken hoe onze AI-oplossingen uw bedrijf kunnen transformeren? Neem contact op met ons team.',
      email: 'E-mail',
      phone: 'Telefoon',
      workingHours: 'Werkuren',
      workingHoursValue: 'Maandag tot vrijdag: 9:00 - 18:00 CET',
      workingHoursNote: 'We reageren meestal binnen 24 uur',
      sendMessage: 'Stuur ons een bericht'
    },
    getStartedPage: {
      title: 'Begin Je AI-Reis',
      description: 'Vul het onderstaande formulier in om aan de slag te gaan met onze AI-oplossingen op maat van uw bedrijfsbehoeften.',
      howItWorks: 'Hoe het werkt',
      steps: {
        consultation: {
          title: 'Consultatie',
          description: 'We beginnen met een grondige consultatie om uw bedrijf, uitdagingen en doelen te begrijpen.'
        },
        customization: {
          title: 'Aanpassing',
          description: 'Ons team ontwikkelt een op maat gemaakte oplossing die specifiek is ontworpen voor uw unieke bedrijfsbehoeften.'
        },
        implementation: {
          title: 'Implementatie',
          description: 'We integreren onze AI-oplossingen naadloos met uw bestaande systemen en bieden uitgebreide training.'
        }
      }
    },
    restaurantsPage: {
      hero: {
        titles: ['Een Reservering', 'Menu Navigatie', 'Directe Ondersteuning'],
        subtitle: 'Door AI aangestuurde chatbots en spraakagenten speciaal ontworpen voor restaurants',
        ctaText: 'Verbeter Uw Restaurant'
      },
      features: 'Belangrijkste Functies voor Restaurants',
      elevate: 'Verbeter Uw Restaurant Ervaring',
      description: 'Van reserveringen tot menuvragen, onze AI-oplossingen handelen alles af zodat uw personeel zich kan concentreren op het creëren van uitzonderlijke eetbelevenissen.'
    },
    restaurantFeatures: {
      customerSupport: {
        title: '24/7 Klantondersteuning',
        description: 'Bied rond de klok hulp aan uw klanten, beantwoord vragen en los problemen direct op.'
      },
      satisfaction: {
        title: 'Verhoogde Tevredenheid',
        description: 'Verbeter de klanttevredenheid met snelle, consistente en behulpzame antwoorden op alle vragen.'
      }
    },
    restaurantSolutions: {
      chatbot: {
        title: 'AI Chatbot voor Restaurants',
        description: 'Een intelligente chatbot die reserveringen, menuvragen en klantenondersteuning naadloos afhandelt.',
        features: {
          support: '24/7 klantondersteuning',
          menu: 'Gedetailleerde menu-informatie',
          allergen: 'Allergeneninformatie',
          multilingual: 'Meertalige mogelijkheden',
          reservation: 'Reserveringsbeheer'
        }
      },
      voice: {
        title: 'Spraakagent voor Restaurants',
        description: 'Een geavanceerde spraakagent die telefoongesprekken, reserveringen en vragen afhandelt met natuurlijke conversatie.',
        features: {
          support: 'Natuurlijke gespreksflow',
          menu: 'Menu-aanbevelingen',
          faq: 'Afhandeling van veelgestelde vragen',
          availability: 'Controle van tafelbeschikbaarheid',
          reservations: 'Naadloos reserveren'
        }
      }
    },
    hotelsPage: {
      hero: {
        titles: ['Efficiënte Check-ins', 'Gast Assistentie', 'Conciërge Service'],
        subtitle: 'Door AI aangestuurde oplossingen om de gastervaring te verbeteren en hoteloperaties te stroomlijnen',
        ctaText: 'Verbeter Gastervaring'
      },
      features: 'Belangrijkste Functies voor Hotels',
      revolutionize: 'Revolutioneer Uw Hoteloperaties',
      description: 'Van boekingen tot gastvragen, onze AI-oplossingen handelen alles af zodat uw personeel zich kan concentreren op het creëren van memorabele verblijven.'
    },
    hotelFeatures: {
      roomBooking: {
        title: 'Kamerboeking',
        description: 'Stroomlijn het boekingsproces met door AI aangestuurde assistentie die reserveringen efficiënt afhandelt.'
      },
      guestExperience: {
        title: 'Gastervaring',
        description: 'Verbeter de gasttevredenheid met onmiddellijke antwoorden op vragen en gepersonaliseerde aanbevelingen.'
      },
      dynamicPricing: {
        title: 'Dynamische Prijzen',
        description: 'Optimaliseer kamertarieven op basis van vraag, seizoensgebondenheid en concurrentie om inkomsten te maximaliseren.'
      },
      virtualConcierge: {
        title: 'Virtuele Conciërge',
        description: 'Bied gasten 24/7 toegang tot informatie over hotelvoorzieningen, lokale attracties en diensten.'
      },
      multiChannel: {
        title: 'Multi-Channel Ondersteuning',
        description: 'Communiceer met gasten via alle communicatiekanalen, van website tot messaging apps.'
      },
      customerSupport: {
        title: 'Klantondersteuning',
        description: 'Handel klantvragen, klachten en feedback prompt en efficiënt af.'
      },
      satisfaction: {
        title: 'Tevredenheidsonderzoeken',
        description: 'Verzamel en analyseer automatisch feedback van gasten om diensten voortdurend te verbeteren.'
      },
      loyalty: {
        title: 'Loyaliteitsprogramma\'s',
        description: 'Beheer en promoot loyaliteitsprogramma\'s om herhaalde boekingen en verwijzingen te stimuleren.'
      }
    },
    hotelSolutions: {
      chatbot: {
        title: 'AI Chatbot voor Hotels',
        description: 'Een intelligente chatbot die boekingen, gastvragen en serviceverzoeken naadloos afhandelt.',
        features: {
          support: '24/7 gastondersteuning',
          reservations: 'Kamerboeking beheer',
          recommendations: 'Lokale aanbevelingen',
          multilingual: 'Meertalige mogelijkheden',
          checkin: 'Express check-in assistentie'
        }
      },
      voice: {
        title: 'Spraakagent voor Hotels',
        description: 'Een geavanceerde spraakagent die telefoongesprekken, reserveringen en gastdiensten afhandelt met natuurlijke conversatie.',
        features: {
          support: 'Natuurlijke gespreksflow',
          concierge: 'Virtuele conciërgediensten',
          roomService: 'Roomservice bestellen',
          bookings: 'Naadloze kamerboekingen',
          local: 'Informatie over de omgeving'
        }
      }
    },
    dealershipsPage: {
      hero: {
        titles: ['Hoge Kwaliteit', 'Afspraken Inplannen', 'Lead Generatie'],
        subtitle: 'Door AI aangestuurde oplossingen om klantbetrokkenheid te verbeteren en verkopen te stimuleren voor autodealers',
        ctaText: 'Boost Uw Dealership'
      },
      features: 'Belangrijkste Functies voor Dealerships',
      drive: 'Drijf Uw Verkoop Vooruit',
      description: 'Van inventarisvragen tot het plannen van proefritten, onze AI-oplossingen handelen alles af zodat uw team zich kan concentreren op het sluiten van deals.'
    },
    dealershipFeatures: {
      inventory: {
        title: 'Inventarisbeheer',
        description: 'Houd klanten op de hoogte van uw nieuwste voertuigen met realtime inventarisinformatie.'
      },
      customerExperience: {
        title: 'Klantervaring',
        description: 'Verbeter de klanttevredenheid met onmiddellijke antwoorden op vragen en gepersonaliseerde aanbevelingen.'
      },
      dynamicPricing: {
        title: 'Dynamische Prijzen',
        description: 'Bied klanten up-to-date prijsinformatie op basis van huidige promoties en financieringsopties.'
      },
      leadGeneration: {
        title: 'Lead Generatie',
        description: 'Vang leads op en kwalificeer ze 24/7, zodat geen enkele potentiële klant door de mazen van het net glipt.'
      },
      multiChannel: {
        title: 'Multi-Channel Ondersteuning',
        description: 'Communiceer met klanten via alle communicatiekanalen, van website tot messaging apps.'
      },
      customerSupport: {
        title: 'Klantondersteuning',
        description: 'Handel klantvragen, klachten en feedback prompt en efficiënt af.'
      },
      satisfaction: {
        title: 'Tevredenheidsonderzoeken',
        description: 'Verzamel en analyseer automatisch klantfeedback om diensten voortdurend te verbeteren.'
      },
      followUp: {
        title: 'Geautomatiseerde Follow-ups',
        description: 'Onderhoud klantrelaties met tijdige, gepersonaliseerde follow-up communicatie.'
      }
    },
    dealershipSolutions: {
      chatbot: {
        title: 'AI Chatbot voor Dealerships',
        description: 'Een intelligente chatbot die inventarisvragen, financieringsvragen en afsprakenplanning naadloos afhandelt.',
        features: {
          support: '24/7 klantondersteuning',
          inventory: 'Realtime inventariszoekactie',
          financing: 'Uitleg over financieringsopties',
          multilingual: 'Meertalige mogelijkheden',
          scheduling: 'Proefrit plannen'
        }
      },
      voice: {
        title: 'Spraakagent voor Dealerships',
        description: 'Een geavanceerde spraakagent die telefoongesprekken, afspraken en klantvragen afhandelt met natuurlijke conversatie.',
        features: {
          support: 'Natuurlijke gespreksflow',
          leads: 'Lead kwalificatie',
          appointments: 'Afsprakenplanning',
          follow: 'Geautomatiseerde follow-ups',
          service: 'Verbinding met serviceafdeling'
        }
      }
    },
    chatbotPage: {
      hero: {
        subtitle: 'Transformeer uw klantenservice met onze door AI aangestuurde chatbot-oplossingen',
        ctaText: 'Aan de slag'
      },
      features: 'Belangrijkste Functies',
      pricingPlans: 'Prijsplannen',
      nextGen: 'Next-Generation Klantenondersteuning',
      description: 'Onze chatbots behandelen klantvragen, verstrekken informatie en voeren taken 24/7 uit, zodat uw team zich kan concentreren op wat het belangrijkst is.'
    },
    voiceAgentPage: {
      hero: {
        titles: ['Beantwoord Oproepen', 'Maak Afspraken', 'Kwalificeer Leads'],
        subtitle: 'Transformeer uw telefonische ondersteuning met onze door AI aangestuurde spraakagent-oplossingen',
        ctaText: 'Aan de slag'
      },
      pricingPlans: 'Prijsplannen',
      revolutionary: 'Revolutionaire Spraak-AI Technologie',
      description: 'Onze spraakagenten handelen telefoongesprekken af, beantwoorden vragen en plannen afspraken met natuurlijke, menselijke conversatie.'
    },
    featuresSection: {
      voice: {
        support: {
          title: '24/7 Oproep Ondersteuning',
          description: 'Onze spraakagenten beantwoorden rond de klok oproepen, zodat u nooit een klantvraag of kans mist.'
        },
        natural: {
          title: 'Natuurlijke Conversatie',
          description: 'Geavanceerde AI-technologie maakt mensachtige gesprekken mogelijk die natuurlijk en boeiend aanvoelen voor bellers.'
        },
        transcription: {
          title: 'Oproep Transcriptie',
          description: 'Transcribeer en analyseer automatisch oproepen voor waardevolle inzichten en kwaliteitsborging.'
        },
        multilingual: {
          title: 'Meertalige Ondersteuning',
          description: 'Onze spraakagenten spreken meerdere talen, waardoor u een divers klantenbestand kunt bedienen.'
        },
        integration: {
          title: 'CRM Integratie',
          description: 'Integreer naadloos met uw bestaande CRM-systeem om klantinteracties bij te houden en leads op te volgen.'
        }
      }
    },
    solutions: {
      title: 'Onze Oplossingen',
      chatbot: {
        title: 'AI Chatbot',
        description: 'Een intelligente chatbot die klantvragen afhandelt, informatie verstrekt en 24/7 taken uitvoert.',
        features: {
          support: '24/7 klantondersteuning',
          menu: 'Product/dienst informatie',
          allergen: 'FAQ afhandeling',
          multilingual: 'Meertalige mogelijkheden',
          reservation: 'Afsprakenplanning'
        }
      },
      voice: {
        title: 'AI Spraakagent',
        description: 'Een geavanceerde spraakagent die telefoongesprekken afhandelt, vragen beantwoordt en afspraken plant met natuurlijke conversatie.',
        features: {
          support: 'Natuurlijke gespreksflow',
          menu: 'Informatielevering',
          faq: 'Afhandeling van veelgestelde vragen',
          availability: 'Beschikbaarheidscontroles',
          reservations: 'Afspraken boeken'
        }
      }
    },
    pricing: {
      title: 'Eenvoudige, Transparante Prijzen',
      subtitle: 'Kies het plan dat bij uw bedrijfsbehoeften past',
      benefits: {
        support: '24/7 Ondersteuning',
        basicMenu: 'Basis Menu Integratie',
        advancedMenu: 'Geavanceerde Menu Integratie',
        fullSystem: 'Volledige Systeemintegratie',
        conversations: 'Tot 500 gesprekken',
        unlimited: 'Onbeperkte gesprekken',
        email: 'E-mail Ondersteuning',
        priority: 'Prioriteitsondersteuning',
        prioritySupport: '24/7 Prioriteitsondersteuning',
        custom: 'Aangepaste Branding',
        analytics: 'Geavanceerde Analyses',
        callHandling: 'Basis Oproepafhandeling',
        calls: 'Tot 100 oproepen/mnd',
        callsAdvanced: 'Tot 500 oproepen/mnd',
        unlimitedCalls: 'Onbeperkte oproepen',
        voiceTraining: 'Spraaktraining',
        callAnalytics: 'Oproep Analyses',
        basicAnalytics: 'Basis Analyses',
        advancedCall: 'Geavanceerde Oproepafhandeling',
        callCenter: 'Volledige Call Center Integratie'
      },
      chatbot: {
        starter: {
          tier: 'Starter',
          price: '€400',
          monthlyPrice: '€40/mnd',
          bestFor: 'Best voor kleine restaurants',
          cta: 'Start Gratis Proef'
        },
        pro: {
          tier: 'Pro',
          price: '€600',
          monthlyPrice: '€60/mnd',
          bestFor: 'Best voor groeiende restaurants',
          cta: 'Start Gratis Proef'
        },
        enterprise: {
          tier: 'Enterprise',
          price: 'Aangepast',
          bestFor: 'Best voor grotere restaurants',
          cta: 'Contact Verkoop'
        }
      },
      voice: {
        starter: {
          tier: 'Starter',
          price: '€499',
          monthlyPrice: '€79/mnd',
          bestFor: 'Best voor kleine bedrijven',
          cta: 'Start Gratis Proef'
        },
        pro: {
          tier: 'Pro',
          price: '€999',
          monthlyPrice: '€149/mnd',
          bestFor: 'Best voor groeiende bedrijven',
          cta: 'Start Gratis Proef'
        },
        enterprise: {
          tier: 'Enterprise',
          price: 'Aangepast',
          bestFor: 'Best voor grote operaties',
          cta: 'Contact Verkoop'
        }
      }
    },
    faq: {
      title: 'Veelgestelde Vragen',
      description: 'Vind antwoorden op veelgestelde vragen over onze AI-oplossingen',
      voice: {
        items: [
          {
            question: 'Hoe behandelt de spraakagent complexe vragen?',
            answer: 'Onze spraakagenten zijn ontworpen om een breed scala aan vragen af te handelen. Voor complexe scenario\'s kunnen ze naadloos overdragen naar een menselijke agent wanneer nodig, zodat uw klanten altijd de ondersteuning krijgen die ze nodig hebben.'
          },
          {
            question: 'Welke talen worden ondersteund door de spraakagent?',
            answer: 'Onze spraakagenten ondersteunen meerdere talen, waaronder Engels, Nederlands, Frans, Duits en Spaans. We kunnen extra talen toevoegen op basis van uw specifieke vereisten.'
          },
          {
            question: 'Hoe veilig zijn gesprekken met de spraakagent?',
            answer: 'Veiligheid is een topprioriteit. Alle gesprekken zijn versleuteld, en we voldoen aan relevante gegevensbeschermingsvoorschriften, waaronder AVG. We slaan geen gevoelige klantinformatie op tenzij expliciet geautoriseerd.'
          },
          {
            question: 'Kan de spraakagent integreren met ons bestaande telefoonsysteem?',
            answer: 'Ja, onze spraakagenten zijn ontworpen om naadloos te integreren met de meeste moderne telefoonsystemen en VoIP-providers. Ons team zal met u samenwerken om een soepel integratieproces te garanderen.'
          },
          {
            question: 'Hoe lang duurt het om een spraakagent te implementeren?',
            answer: 'Typisch kunnen we een volledig functionele spraakagent implementeren binnen 2-4 weken na onze eerste consultatie. Dit omvat aanpassing, training en integratie met uw bestaande systemen.'
          }
        ]
      }
    },
    privacyPolicy: {
      title: 'Privacybeleid',
      lastUpdated: 'Laatst bijgewerkt: 1 april 2025',
      sections: {
        collect: {
          title: 'Informatie die we verzamelen',
          content: 'We verzamelen informatie die u rechtstreeks aan ons verstrekt wanneer u formulieren invult op onze website, met ons communiceert via e-mail of telefoon, of interactie heeft met onze AI-oplossingen. Dit kan uw naam, e-mailadres, telefoonnummer, bedrijfsnaam en andere informatie omvatten die u kiest te verstrekken.'
        },
        use: {
          title: 'Hoe we uw informatie gebruiken',
          content: 'We gebruiken de informatie die we verzamelen om onze diensten te leveren, te onderhouden en te verbeteren, om met u te communiceren, om op uw vragen te reageren en om uw ervaring met onze AI-oplossingen aan te passen en te verbeteren.'
        },
        sharing: {
          title: 'Informatie delen',
          content: 'We kunnen uw informatie delen met externe serviceproviders die diensten namens ons uitvoeren, zoals hosting, gegevensanalyse, betalingsverwerking en klantenservice. We kunnen uw informatie ook delen zoals vereist door de wet of om onze rechten en belangen te beschermen.'
        },
        security: {
          title: 'Beveiliging',
          content: 'We nemen redelijke maatregelen om uw persoonlijke informatie te beschermen tegen verlies, diefstal, misbruik en ongeautoriseerde toegang, openbaarmaking, wijziging en vernietiging.'
        }
      }
    },
    termsOfService: {
      title: 'Servicevoorwaarden',
      lastUpdated: 'Laatst bijgewerkt: 1 april 2025',
      sections: {
        acceptance: {
          title: 'Aanvaarding van Voorwaarden',
          content: 'Door toegang te krijgen tot of gebruik te maken van onze website en diensten, gaat u akkoord met deze Servicevoorwaarden. Als u niet akkoord gaat met deze voorwaarden, gebruik dan onze diensten niet.'
        },
        license: {
          title: 'Licentie en Gebruik',
          content: 'We verlenen u een beperkte, niet-exclusieve, niet-overdraagbare en herroepbare licentie om onze diensten te gebruiken voor uw zakelijke doeleinden. U mag onze diensten niet gebruiken voor illegale of ongeautoriseerde doeleinden.'
        },
        modifications: {
          title: 'Wijzigingen aan Dienst',
          content: 'We behouden ons het recht voor om onze diensten tijdelijk of permanent te wijzigen of te onderbreken, met of zonder kennisgeving. We zijn niet aansprakelijk jegens u of derden voor enige wijziging, opschorting of onderbreking van de dienst.'
        },
        liability: {
          title: 'Beperking van Aansprakelijkheid',
          content: 'In geen geval is Serfeo aansprakelijk voor enige indirecte, incidentele, speciale, gevolgschade of punitieve schade, inclusief verlies van winst, gegevens of andere immateriële verliezen, voortvloeiend uit uw gebruik van onze diensten.'
        }
      }
    },
    demoPage: {
      title: 'Vraag een Demo Aan',
      description: 'Bekijk onze AI-oplossingen in actie en ontdek hoe ze uw bedrijf kunnen transformeren',
      startButton: 'Start Gratis Proef'
    }
  }
};