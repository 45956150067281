"use client";

import * as React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDown, Mail } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

interface FaqSectionProps extends React.HTMLAttributes<HTMLElement> {
  title: string;
  description?: string;
  items: {
    question: string;
    answer: string;
  }[];
  contactInfo?: {
    title: string;
    description: string;
    buttonText: string;
    onContact?: () => void;
  };
}

const FaqSection = React.forwardRef<HTMLElement, FaqSectionProps>(
  ({ className, title, description, items, contactInfo, ...props }, ref) => {
    const navigate = useNavigate();
    
    const handleContact = () => {
      if (contactInfo?.onContact) {
        contactInfo.onContact();
      } else {
        navigate('/contact');
      }
    };
    
    return (
      <section
        ref={ref}
        className={cn(
          "py-12 sm:py-16 w-full bg-gradient-to-b from-transparent via-gray-900/50 to-transparent",
          className
        )}
        {...props}
      >
        <div className="container max-w-4xl px-4 mx-auto">
          {/* Header */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="max-w-2xl mx-auto text-center mb-8 sm:mb-12"
          >
            <h2 className="text-2xl sm:text-3xl font-semibold mb-3 bg-gradient-to-r from-white via-white/80 to-white bg-clip-text text-transparent">
              {title}
            </h2>
            {description && (
              <p className="text-sm sm:text-base text-gray-400">{description}</p>
            )}
          </motion.div>

          {/* FAQ Items */}
          <div className="max-w-2xl mx-auto space-y-2 sm:space-y-3">
            {items.map((item, index) => (
              <FaqItem
                key={index}
                question={item.question}
                answer={item.answer}
                index={index}
              />
            ))}
          </div>
          
          {/* Contact Info Section */}
          {contactInfo && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="mt-12 sm:mt-16 max-w-md mx-auto text-center"
            >
              <div className="p-6 sm:p-8 rounded-lg border border-gray-800 bg-gradient-to-br from-gray-900/80 to-gray-900/20 backdrop-blur-sm">
                <h3 className="text-xl font-semibold mb-3 text-white">
                  {contactInfo.title}
                </h3>
                <p className="text-sm text-gray-400 mb-6">
                  {contactInfo.description}
                </p>
                <Button
                  onClick={handleContact}
                  className="w-full gap-2 bg-blue-500 hover:bg-blue-600"
                >
                  <Mail className="h-4 w-4" />
                  {contactInfo.buttonText}
                </Button>
              </div>
            </motion.div>
          )}
        </div>
      </section>
    );
  }
);
FaqSection.displayName = "FaqSection";

// Internal FaqItem component
const FaqItem = React.forwardRef<
  HTMLDivElement,
  {
    question: string;
    answer: string;
    index: number;
  }
>((props, ref) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { question, answer, index } = props;

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2, delay: index * 0.1 }}
      className={cn(
        "group rounded-lg",
        "transition-all duration-200 ease-in-out",
        "border border-gray-800",
        isOpen
          ? "bg-gradient-to-br from-gray-900 via-gray-800/50 to-gray-900"
          : "hover:bg-gray-900/50"
      )}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={cn(
          "w-full flex items-center justify-between px-4 sm:px-6 py-3 sm:py-4",
          "min-h-[56px] text-left transition-all duration-200",
          "focus:outline-none focus:ring-2 focus:ring-blue-500/30 focus:ring-offset-2 focus:ring-offset-gray-900/50 rounded-lg"
        )}
      >
        <h3
          className={cn(
            "text-sm sm:text-base font-medium transition-colors duration-200",
            "text-gray-300 pr-4",
            isOpen && "text-white"
          )}
        >
          {question}
        </h3>
        <motion.div
          animate={{
            rotate: isOpen ? 180 : 0,
            scale: isOpen ? 1.1 : 1,
          }}
          transition={{ duration: 0.2 }}
          className={cn(
            "p-0.5 rounded-full flex-shrink-0",
            "transition-colors duration-200",
            isOpen ? "text-blue-400" : "text-gray-500"
          )}
        >
          <ChevronDown className="h-4 w-4 sm:h-5 sm:w-5" />
        </motion.div>
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{
              height: "auto",
              opacity: 1,
              transition: { duration: 0.2, ease: "easeOut" },
            }}
            exit={{
              height: 0,
              opacity: 0,
              transition: { duration: 0.2, ease: "easeIn" },
            }}
            className="overflow-hidden"
          >
            <div className="px-4 sm:px-6 pb-4 pt-0 sm:pt-1">
              <motion.p
                initial={{ y: -10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                className="text-sm text-gray-400 leading-relaxed"
              >
                {answer}
              </motion.p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
});
FaqItem.displayName = "FaqItem";

export { FaqSection };