import { Hero } from "@/components/ui/animated-hero";
import { BackgroundPaths } from "@/components/ui/background-paths";
import { BeamsBackground } from "@/components/ui/beams-background";
import { useNavigate } from 'react-router-dom';
import { useLanguage } from "@/context/LanguageContext";
import { DealershipFeatures } from "@/components/ui/dealership-features";
import { DealershipSolutionCardsSection } from "@/components/ui/dealership-solution-cards";
import { SEO } from "@/components/SEO";

export default function DealershipsPage() {
  const navigate = useNavigate();
  const { t } = useLanguage();

  return (
    <>
      <SEO 
        title="AI Solutions for Car Dealerships | Serfeo | Belgium"
        description="Optimize customer inquiries, appointment scheduling, and follow-ups for your car dealership with Serfeo's AI solutions."
        schemaType="LocalBusiness"
        schemaData={{
          name: "Serfeo - Car Dealership AI Solutions",
          description: "AI automation for car dealerships: lead generation, appointment setting, and customer service",
          makesOffer: {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "AI Chatbot for Car Dealerships",
              description: "AI-powered chatbot solutions for car dealership operations"
            }
          }
        }}
        canonicalOverride="https://serfeo.com/dealerships"
      />
      <div className="bg-black min-h-screen">
        <BeamsBackground intensity="medium">
          <Hero
            titles={[t('dealershipsPage.hero.titles.0'), t('dealershipsPage.hero.titles.1'), t('dealershipsPage.hero.titles.2')]}
            subtitle={t('dealershipsPage.hero.subtitle')}
            ctaText={t('dealershipsPage.hero.ctaText')}
            onCtaClick={() => navigate('/getstarted')}
            solutionsSectionId="dealership-solutions"
            showSolutionsButton={true}
            useAuroraBackground={false}
            makeEveryText={t('hero.makeEvery')}
          />
        </BeamsBackground>
        
        {/* Empty div to maintain the id for scroll navigation */}
        <div id="dealership-solutions"></div>
        
        {/* Features section added above pricing */}
        <div className="container mx-auto px-4 py-12">
          <h2 className="text-3xl font-bold text-center mb-8">{t('dealershipsPage.features')}</h2>
          <DealershipFeatures />
        </div>
        
        {/* Solution Cards Section */}
        <DealershipSolutionCardsSection />
        
        {/* BackgroundPaths moved to bottom, just above footer */}
        <BackgroundPaths
          title={t('dealershipsPage.drive')}
          description={t('dealershipsPage.description')}
          buttonText={t('cta.button')}
          onButtonClick={() => navigate('/getstarted')}
        />
      </div>
    </>
  );
}