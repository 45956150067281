import { PricingCard } from "./pricing-card";
import { useLanguage } from "@/context/LanguageContext";
import { useNavigate } from "react-router-dom";

export function ChatbotPricingSection() {
  const { t } = useLanguage();
  const navigate = useNavigate();
  
  const chatbotPlans = {
    starter: {
      tier: "Starter",
      price: "€400",
      monthlyPrice: "€40/mo",
      bestFor: "Best for small restaurants",
      CTA: "Start Free Trial",
      benefits: [
        { text: "24/7 Support", checked: true },
        { text: "Menu Access", checked: true },
        { text: "Reservations", checked: true },
        { text: "Monthly Report", checked: true },
        { text: "Multilingual", checked: true },
        { text: "Priority Support", checked: false },
        { text: "Unlimited Chats", checked: false },
        { text: "Optimized UI", checked: false },
        { text: "2 Workflows", checked: false },
        { text: "Integrations", checked: false },
        { text: "Human Handoff", checked: false },
        { text: "Dashboard", checked: false },
        { text: "Unlimited Workflows", checked: false },
      ]
    },
    pro: {
      tier: "Pro",
      price: "€600",
      monthlyPrice: "€60/mo",
      bestFor: "Best for scaling restaurants",
      CTA: "Start Free Trial",
      benefits: [
        { text: "24/7 Support", checked: true },
        { text: "Menu Access", checked: true },
        { text: "Reservations", checked: true },
        { text: "Monthly Report", checked: true },
        { text: "Multilingual", checked: true },
        { text: "Priority Support", checked: true },
        { text: "Unlimited Chats", checked: true },
        { text: "Optimized UI", checked: true },
        { text: "2 Workflows", checked: true },
        { text: "Integrations", checked: true },
        { text: "Human Handoff", checked: false },
        { text: "Dashboard", checked: false },
        { text: "Unlimited Workflows", checked: false },
      ]
    },
    enterprise: {
      tier: "Enterprise",
      price: "Custom",
      bestFor: "Best for bigger restaurants",
      CTA: "Contact Sales",
      benefits: [
        { text: "24/7 Support", checked: true },
        { text: "Menu Access", checked: true },
        { text: "Reservations", checked: true },
        { text: "Monthly Report", checked: true },
        { text: "Multilingual", checked: true },
        { text: "Priority Support", checked: true },
        { text: "Unlimited Chats", checked: true },
        { text: "Optimized UI", checked: true },
        { text: "2 Workflows", checked: true },
        { text: "Integrations", checked: true },
        { text: "Human Handoff", checked: true },
        { text: "Dashboard", checked: true },
        { text: "Unlimited Workflows", checked: true },
      ]
    }
  };

  return (
    <section className="relative overflow-hidden bg-background text-foreground">
      <div className="relative z-10 mx-auto max-w-5xl px-4 py-12 md:px-8">
        <div className="mb-6 space-y-2">
          <h2 className="text-center text-3xl font-semibold leading-tight sm:text-4xl sm:leading-tight md:text-5xl md:leading-tight">
            {t('pricing.title')}
          </h2>
          <p className="text-center text-base text-muted-foreground md:text-lg">
            {t('pricing.subtitle')}
          </p>
        </div>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          <div onClick={() => navigate('/getstarted')} className="cursor-pointer">
            <PricingCard {...chatbotPlans.starter} />
          </div>
          <div onClick={() => navigate('/getstarted')} className="cursor-pointer">
            <PricingCard {...chatbotPlans.pro} />
          </div>
          <div onClick={() => navigate('/getstarted')} className="cursor-pointer">
            <PricingCard {...chatbotPlans.enterprise} />
          </div>
        </div>
      </div>
    </section>
  );
}