import { BackgroundPaths } from "@/components/ui/background-paths";
import { LeadForm } from "@/components/LeadForm";
import { Mail, Phone, Clock } from "lucide-react";
import { useLanguage } from "@/context/LanguageContext";
import { SEO } from "@/components/SEO";

export default function ContactPage() {
  const { t } = useLanguage();

  return (
    <>
      <SEO 
        title="Contact Serfeo | AI Automation Solutions | Belgium"
        description="Get in touch with Serfeo's team to discuss how our AI solutions can transform your business operations."
        schemaType="LocalBusiness"
        canonicalOverride="https://serfeo.com/contact"
      />
      <div className="bg-black min-h-screen">
        {/* BackgroundPaths moved outside the container to span full width */}
        <BackgroundPaths
          title={t('contactPage.getInTouch')}
          description={t('contactPage.description')}
          className="mt-0 pt-0"
          textAlign="center"
          showGradients={false}
          showBlackGradient={true}
        />
          
        <div className="container mx-auto px-4 pb-20">
          <div className="max-w-6xl mx-auto mt-10">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-start">
              {/* Contact Information (Left Side) */}
              <div className="space-y-10">
                <div className="relative">
                  {/* Blue glow effect */}
                  <div className="absolute -inset-1 bg-blue-500/10 rounded-lg blur-md opacity-70"></div>
                  <div className="relative bg-gray-900/60 backdrop-blur-sm border border-gray-800 rounded-lg p-8 space-y-8">
                    <div className="flex items-start space-x-4">
                      <div className="bg-blue-900/30 p-3 rounded-lg">
                        <Mail className="w-6 h-6 text-blue-400" />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold text-white mb-1">{t('contactPage.email')}</h3>
                        <a href="mailto:serfeo28@gmail.com" className="text-gray-300 hover:text-blue-400 transition-colors">
                          serfeo28@gmail.com
                        </a>
                      </div>
                    </div>
                    
                    <div className="flex items-start space-x-4">
                      <div className="bg-blue-900/30 p-3 rounded-lg">
                        <Phone className="w-6 h-6 text-blue-400" />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold text-white mb-1">{t('contactPage.phone')}</h3>
                        <a href="tel:+32468153990" className="text-gray-300 hover:text-blue-400 transition-colors">
                          +32 468 15 39 90
                        </a>
                      </div>
                    </div>
                    
                    <div className="flex items-start space-x-4">
                      <div className="bg-blue-900/30 p-3 rounded-lg">
                        <Clock className="w-6 h-6 text-blue-400" />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold text-white mb-1">{t('contactPage.workingHours')}</h3>
                        <p className="text-gray-300">{t('contactPage.workingHoursValue')}</p>
                        <p className="text-gray-400 text-sm mt-1">{t('contactPage.workingHoursNote')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Contact Form (Right Side) */}
              <div className="relative">
                {/* Subtle blue glow behind the form */}
                <div className="absolute -inset-1 bg-blue-500/5 rounded-lg blur-lg"></div>
                <div className="relative bg-gray-900/60 backdrop-blur-sm border border-gray-800 rounded-lg p-8">
                  <h3 className="text-xl font-semibold mb-6 text-white">{t('contactPage.sendMessage')}</h3>
                  <LeadForm source="contact" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}