import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLanguage } from "@/context/LanguageContext";

interface LeadFormProps {
  source: string;
  onSuccess?: () => void;
}

export function LeadForm({ source, onSuccess }: LeadFormProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    phone: '',
    message: ''
  });
  const { t } = useLanguage();

  const submitToAirtable = async (data: typeof formData) => {
    try {
      const response = await fetch('https://api.airtable.com/v0/app1EKPChqrQwh7tu/Contact%20Form', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer paty8xKPmJkevqLJv.500013b13af742ed5206ae0a147d7eb68c6bbe60b7018db698b3939375983bd1',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          records: [
            {
              fields: {
                "First Name": data.firstName,
                "Last Name": data.lastName,
                "Business Email": data.email,
                "Company Name": data.company,
                "Phone Number": data.phone,
                "Message": data.message || ''
              }
            }
          ]
        })
      });

      if (!response.ok) {
        throw new Error('Failed to submit to Airtable');
      }
    } catch (error) {
      console.error('Airtable submission error:', error);
      throw error;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await submitToAirtable(formData);

      toast.success(t('leadForm.success'));
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        phone: '',
        message: ''
      });
      
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Something went wrong. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.id]: e.target.value
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <Toaster position="top-right" />
      <div className="grid md:grid-cols-2 gap-6">
        <div>
          <label htmlFor="firstName" className="block text-sm font-medium mb-2">
            {t('leadForm.firstName')}
          </label>
          <input
            type="text"
            id="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="w-full px-4 py-2 bg-gray-900 rounded-lg border border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            placeholder="John"
            required
          />
        </div>
        
        <div>
          <label htmlFor="lastName" className="block text-sm font-medium mb-2">
            {t('leadForm.lastName')}
          </label>
          <input
            type="text"
            id="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="w-full px-4 py-2 bg-gray-900 rounded-lg border border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            placeholder="Doe"
            required
          />
        </div>
      </div>
      
      <div>
        <label htmlFor="email" className="block text-sm font-medium mb-2">
          {t('leadForm.email')}
        </label>
        <input
          type="email"
          id="email"
          value={formData.email}
          onChange={handleChange}
          className="w-full px-4 py-2 bg-gray-900 rounded-lg border border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          placeholder="john@example.com"
          required
        />
      </div>
      
      <div>
        <label htmlFor="company" className="block text-sm font-medium mb-2">
          {t('leadForm.company')}
        </label>
        <input
          type="text"
          id="company"
          value={formData.company}
          onChange={handleChange}
          className="w-full px-4 py-2 bg-gray-900 rounded-lg border border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          placeholder="Your Company"
          required
        />
      </div>
      
      <div>
        <label htmlFor="phone" className="block text-sm font-medium mb-2">
          {t('leadForm.phone')}
        </label>
        <input
          type="tel"
          id="phone"
          value={formData.phone}
          onChange={handleChange}
          className="w-full px-4 py-2 bg-gray-900 rounded-lg border border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          placeholder="+1 (555) 000-0000"
          required
        />
      </div>

      <div>
        <label htmlFor="message" className="block text-sm font-medium mb-2">
          {t('leadForm.message')}
        </label>
        <textarea
          id="message"
          value={formData.message}
          onChange={handleChange}
          rows={4}
          className="w-full px-4 py-2 bg-gray-900 rounded-lg border border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          placeholder={t('leadForm.messagePlaceholder')}
        />
      </div>
      
      <button
        type="submit"
        disabled={isSubmitting}
        className="w-full px-8 py-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors shadow-lg shadow-blue-500/20 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isSubmitting ? t('leadForm.submitting') : t('leadForm.getStarted')}
      </button>
    </form>
  );
}