import { cn } from "@/lib/utils";

export interface TestimonialAuthor {
  name: string;
  handle: string;
  avatar: string;
}

interface TestimonialCardProps {
  author: TestimonialAuthor;
  text: string;
  href?: string;
}

export function TestimonialCard({ author, text, href }: TestimonialCardProps) {
  const Card = href ? 'a' : 'div';
  
  return (
    <Card
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={cn(
        "group relative overflow-hidden rounded-2xl border border-gray-800 bg-gray-900/50 p-6 backdrop-blur-sm",
        "hover:border-blue-900/50 hover:bg-gray-900/70 transition-all duration-200",
        "flex flex-col gap-4 w-[320px] sm:w-[340px]",
        "shadow-lg shadow-blue-900/5 hover:shadow-blue-900/10",
        href && "cursor-pointer"
      )}
    >
      {/* Subtle blue glow on hover */}
      <div className="absolute inset-0 -z-10 bg-blue-500/5 opacity-0 group-hover:opacity-30 blur-xl transition-opacity duration-500"></div>
      
      <div className="flex items-center gap-4">
        <img
          src={author.avatar}
          alt={author.name}
          className="h-12 w-12 rounded-full object-cover ring-2 ring-blue-500/10"
        />
        <div>
          <p className="font-medium text-gray-100">{author.name}</p>
          <p className="text-sm text-gray-400">{author.handle}</p>
        </div>
      </div>
      <p className="text-gray-300">{text}</p>
    </Card>
  );
}