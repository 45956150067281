import { BrowserRouter as Router, Routes, Route, Link, NavLink } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import GetStartedPage from './pages/GetStartedPage';
import RestaurantsPage from './pages/RestaurantsPage';
import HotelsPage from './pages/HotelsPage';
import DealershipsPage from './pages/DealershipsPage';
import VoiceAgentPage from './pages/VoiceAgentPage';
import ChatbotPage from './pages/ChatbotPage';
import DemoPage from './pages/DemoPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import { Button } from './components/ui/button';
import { ChevronDown, Menu, X, Instagram } from 'lucide-react';
import ScrollToTop from './components/ScrollToTop';
import { LanguageProvider, useLanguage } from './context/LanguageContext';
import { LanguageSwitcher } from './components/LanguageSwitcher';

function MainApp() {
  const [servicesOpen, setServicesOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileServicesOpen, setMobileServicesOpen] = useState(false);
  const servicesDropdownRef = useRef<HTMLDivElement>(null);
  const servicesButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useLanguage();

  const toggleServices = () => {
    setServicesOpen(!servicesOpen);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    if (mobileServicesOpen) setMobileServicesOpen(false);
  };

  const toggleMobileServices = () => {
    setMobileServicesOpen(!mobileServicesOpen);
  };
  
  // Close mobile menu when a navigation item is clicked
  const handleMobileNavClick = () => {
    setMobileMenuOpen(false);
    setMobileServicesOpen(false);
  };

  // Handle clicks outside the dropdown
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        servicesDropdownRef.current && 
        servicesButtonRef.current &&
        !servicesDropdownRef.current.contains(event.target as Node) &&
        !servicesButtonRef.current.contains(event.target as Node)
      ) {
        setServicesOpen(false);
      }
    }

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <div className="min-h-screen bg-black text-white">
        <header className="fixed w-full top-0 z-50 backdrop-blur-md bg-transparent border-b border-blue-500/10">
          <div className="container mx-auto px-4">
            <div className="flex items-center justify-between h-16">
              {/* Logo on the left */}
              <Link to="/" className="flex items-center">
                <img src="/Assets/Favicon.png" alt="Serfeo Logo" className="h-10 w-10 brightness-0 invert" />
                <span className="ml-2 text-xl font-bold">SERFEO</span>
              </Link>
              
              {/* Navigation centered */}
              <nav className="hidden md:flex items-center space-x-8 absolute left-1/2 transform -translate-x-1/2">
                <NavLink to="/" className={({isActive}) => isActive ? "text-blue-400" : "hover:text-blue-400 transition-colors"}>
                  {t('nav.home')}
                </NavLink>
                <NavLink to="/about" className={({isActive}) => isActive ? "text-blue-400" : "hover:text-blue-400 transition-colors"}>
                  {t('nav.about')}
                </NavLink>
                <div className="relative">
                  <button 
                    ref={servicesButtonRef}
                    className="flex items-center hover:text-blue-400 transition-colors"
                    onClick={toggleServices}
                  >
                    {t('nav.industries')} <ChevronDown className="ml-1 h-4 w-4" />
                  </button>
                  {servicesOpen && (
                    <div 
                      ref={servicesDropdownRef}
                      className="absolute left-0 mt-2 w-56 rounded-md shadow-lg backdrop-blur-md bg-transparent border border-gray-800/20"
                    >
                      <div className="py-1" role="menu" aria-orientation="vertical">
                        <NavLink to="/restaurants" className="block px-4 py-2 text-sm hover:bg-gray-800/50" role="menuitem">
                          {t('industries.restaurants')}
                        </NavLink>
                        <NavLink to="/hotels" className="block px-4 py-2 text-sm hover:bg-gray-800/50" role="menuitem">
                          {t('industries.hotels')}
                        </NavLink>
                        <NavLink to="/dealerships" className="block px-4 py-2 text-sm hover:bg-gray-800/50" role="menuitem">
                          {t('industries.dealerships')}
                        </NavLink>
                      </div>
                    </div>
                  )}
                </div>
                <NavLink to="/contact" className={({isActive}) => isActive ? "text-blue-400" : "hover:text-blue-400 transition-colors"}>
                  {t('nav.contact')}
                </NavLink>
              </nav>

              {/* Get started button on the right */}
              <div className="flex items-center space-x-3">
                <LanguageSwitcher />
                <Button asChild size="sm" className="hidden md:inline-flex">
                  <Link to="/getstarted">{t('nav.getStarted')}</Link>
                </Button>
                <button
                  className="ml-4 md:hidden"
                  onClick={toggleMobileMenu}
                >
                  {mobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
                </button>
              </div>
            </div>
          </div>
          
          {/* Mobile menu */}
          {mobileMenuOpen && (
            <div className="md:hidden backdrop-blur-md bg-transparent border-b border-gray-800/20">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <NavLink 
                  to="/" 
                  className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-800/50"
                  onClick={handleMobileNavClick}
                >
                  {t('nav.home')}
                </NavLink>
                <NavLink 
                  to="/about" 
                  className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-800/50"
                  onClick={handleMobileNavClick}
                >
                  {t('nav.about')}
                </NavLink>
                <div>
                  <button 
                    className="flex items-center justify-between w-full px-3 py-2 rounded-md text-base font-medium hover:bg-gray-800/50"
                    onClick={toggleMobileServices}
                  >
                    <span>{t('nav.industries')}</span>
                    <ChevronDown className="h-4 w-4" />
                  </button>
                  {mobileServicesOpen && (
                    <div className="pl-4 space-y-1">
                      <NavLink 
                        to="/restaurants" 
                        className="block px-3 py-2 rounded-md text-sm hover:bg-gray-800/50"
                        onClick={handleMobileNavClick}
                      >
                        {t('industries.restaurants')}
                      </NavLink>
                      <NavLink 
                        to="/hotels" 
                        className="block px-3 py-2 rounded-md text-sm hover:bg-gray-800/50"
                        onClick={handleMobileNavClick}
                      >
                        {t('industries.hotels')}
                      </NavLink>
                      <NavLink 
                        to="/dealerships" 
                        className="block px-3 py-2 rounded-md text-sm hover:bg-gray-800/50"
                        onClick={handleMobileNavClick}
                      >
                        {t('industries.dealerships')}
                      </NavLink>
                    </div>
                  )}
                </div>
                <NavLink 
                  to="/contact" 
                  className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-800/50"
                  onClick={handleMobileNavClick}
                >
                  {t('nav.contact')}
                </NavLink>
                <div className="pt-2">
                  <Button 
                    asChild 
                    className="w-full"
                    onClick={handleMobileNavClick}
                  >
                    <Link to="/getstarted">{t('nav.getStarted')}</Link>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </header>
        
        <div className="pt-16">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/getstarted" element={<GetStartedPage />} />
            <Route path="/restaurants" element={<RestaurantsPage />} />
            <Route path="/hotels" element={<HotelsPage />} />
            <Route path="/dealerships" element={<DealershipsPage />} />
            <Route path="/voice-agent" element={<VoiceAgentPage />} />
            <Route path="/chatbot" element={<ChatbotPage />} />
            <Route path="/demo" element={<DemoPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/terms-of-service" element={<TermsOfServicePage />} />
          </Routes>
        </div>
        
        <footer className="bg-black border-t border-gray-800 mt-12">
          <div className="container mx-auto px-4 py-8">
            <div className="flex flex-col items-center mb-8">
              <div className="w-full text-center mb-8">
                <Link to="/" className="flex items-center justify-center">
                  <img src="/Assets/Favicon.png" alt="Serfeo Logo" className="h-8 w-8 brightness-0 invert" />
                  <span className="ml-2 text-xl font-bold text-white">SERFEO</span>
                </Link>
                <p className="mt-2 text-sm text-gray-400">{t('footer.tagline')}</p>
              </div>
              
              <div className="w-full max-w-4xl grid grid-cols-3 gap-8 md:gap-24 text-center">
                <div>
                  <h3 className="text-sm font-semibold text-white tracking-wider uppercase mb-4">{t('footer.company')}</h3>
                  <ul className="space-y-2">
                    <li><Link to="/about" className="text-gray-400 hover:text-white">{t('nav.about')}</Link></li>
                    <li><Link to="/contact" className="text-gray-400 hover:text-white">{t('nav.contact')}</Link></li>
                  </ul>
                </div>
                
                <div>
                  <h3 className="text-sm font-semibold text-white tracking-wider uppercase mb-4">{t('footer.legal')}</h3>
                  <ul className="space-y-2">
                    <li><Link to="/privacy-policy" className="text-gray-400 hover:text-white">{t('footer.privacyPolicy')}</Link></li>
                    <li><Link to="/terms-of-service" className="text-gray-400 hover:text-white">{t('footer.termsOfService')}</Link></li>
                  </ul>
                </div>
                
                <div>
                  <h3 className="text-sm font-semibold text-white tracking-wider uppercase mb-4">{t('footer.connect')}</h3>
                  <ul className="space-y-2">
                    <li>
                      <a 
                        href="https://www.instagram.com/serfeoautomations?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-gray-400 hover:text-white flex items-center justify-center"
                      >
                        <Instagram size={20} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            
            <div className="border-t border-gray-800 pt-8 flex flex-col md:flex-row justify-center text-center">
              <p className="text-sm text-gray-400">{t('footer.copyright')}</p>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

function App() {
  return (
    <HelmetProvider>
      <LanguageProvider>
        <MainApp />
      </LanguageProvider>
    </HelmetProvider>
  );
}

export default App;