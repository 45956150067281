"use client";

import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";

function FloatingPaths({ position }: { position: number }) {
    const paths = Array.from({ length: 36 }, (_, i) => ({
        id: i,
        d: `M-${380 - i * 5 * position} -${189 + i * 6}C-${
            380 - i * 5 * position
        } -${189 + i * 6} -${312 - i * 5 * position} ${216 - i * 6} ${
            152 - i * 5 * position
        } ${343 - i * 6}C${616 - i * 5 * position} ${470 - i * 6} ${
            684 - i * 5 * position
        } ${875 - i * 6} ${684 - i * 5 * position} ${875 - i * 6}`,
        color: `rgba(15,23,42,${0.1 + i * 0.03})`,
        width: 0.5 + i * 0.03,
    }));

    return (
        <div className="absolute inset-0 pointer-events-none z-0">
            <svg
                className="w-full h-full text-white"
                viewBox="0 0 696 316"
                fill="none"
                preserveAspectRatio="xMidYMid meet"
            >
                <title>Background Paths</title>
                {paths.map((path) => (
                    <motion.path
                        key={path.id}
                        d={path.d}
                        stroke="currentColor"
                        strokeWidth={path.width}
                        strokeOpacity={0.1 + path.id * 0.03}
                        initial={{ pathLength: 0.3, opacity: 0.6 }}
                        animate={{
                            pathLength: 1,
                            opacity: [0.3, 0.6, 0.3],
                            pathOffset: [0, 1, 0],
                        }}
                        transition={{
                            duration: 20 + Math.random() * 10,
                            repeat: Number.POSITIVE_INFINITY,
                            ease: "linear",
                        }}
                    />
                ))}
            </svg>
        </div>
    );
}

interface BackgroundPathsProps {
    title: string;
    description?: string;
    buttonText?: string;
    onButtonClick?: () => void;
    className?: string;
    textAlign?: "left" | "center" | "right";
    containerClassName?: string;
    showGradients?: boolean;
    showBlackGradient?: boolean;
}

export function BackgroundPaths({
    title,
    description,
    buttonText,
    onButtonClick,
    className = "",
    textAlign = "center",
    containerClassName = "",
    showGradients = true,
    showBlackGradient = false
}: BackgroundPathsProps) {
    const words = title.split(" ");
    
    const textAlignClass = {
        left: "text-left",
        center: "text-center",
        right: "text-right"
    }[textAlign];

    return (
        <div className={`relative w-full flex items-center justify-center overflow-hidden bg-black py-12 sm:py-16 ${className}`}>
            {/* Blue glow background - increased opacity for better visibility */}
            <div className="absolute inset-0 opacity-40">
                <div className="absolute top-1/4 left-1/3 w-[600px] h-[600px] rounded-full bg-blue-500/20 blur-[80px] animate-pulse-blue"></div>
                <div className="absolute bottom-1/3 right-1/4 w-[500px] h-[500px] rounded-full bg-blue-400/20 blur-[100px] animate-pulse-blue" style={{ animationDelay: "2s" }}></div>
            </div>
            
            {/* Improved SVG rendering with fixed dimensions and explicit paths */}
            <div className="absolute inset-0 w-full h-full scale-150 sm:scale-175 md:scale-150">
                <FloatingPaths position={1} />
                <FloatingPaths position={-1} />
            </div>
            
            {/* Top gradient overlay - only show if showGradients is true */}
            {showGradients && (
                <div className="absolute top-0 left-0 right-0 h-48"
                    style={{
                        backgroundImage: 'linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.95) 25%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.4) 75%, rgba(0, 0, 0, 0) 100%)',
                        zIndex: 5
                    }}>
                </div>
            )}

            <div className={`relative z-10 container mx-auto px-4 md:px-6 ${textAlignClass} ${containerClassName}`}>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 2 }}
                    className={`${textAlign === "center" ? "max-w-4xl mx-auto" : "max-w-4xl"} ${textAlign === "left" ? "ml-0 mr-auto" : ""}`}
                >
                    <h2 className={`text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-6 sm:mb-8 tracking-tighter px-2 ${textAlignClass}`}>
                        {words.map((word, wordIndex) => (
                            <span
                                key={wordIndex}
                                className="inline-block mr-2 sm:mr-4 last:mr-0"
                            >
                                {word.split("").map((letter, letterIndex) => (
                                    <motion.span
                                        key={`${wordIndex}-${letterIndex}`}
                                        initial={{ y: 100, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        transition={{
                                            delay:
                                                wordIndex * 0.1 +
                                                letterIndex * 0.03,
                                            type: "spring",
                                            stiffness: 150,
                                            damping: 25,
                                        }}
                                        className="inline-block text-transparent bg-clip-text 
                                        bg-gradient-to-r from-white to-white/80"
                                    >
                                        {letter}
                                    </motion.span>
                                ))}
                            </span>
                        ))}
                    </h2>

                    {description && (
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.5, duration: 0.8 }}
                            className={`text-base sm:text-lg md:text-xl text-gray-400 mb-6 ${textAlign === "center" ? "max-w-2xl mx-auto" : "max-w-2xl"} ${textAlign === "left" ? "ml-0 mr-auto" : ""} px-4`}
                        >
                            {description}
                        </motion.p>
                    )}

                    {/* Only render button when buttonText is provided */}
                    {buttonText && (
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.8, duration: 0.8 }}
                            className="px-4 relative z-20"
                        >
                            <div className="inline-block group relative bg-gradient-to-b from-blue-500/10 to-black/10 
                                p-px rounded-2xl backdrop-blur-lg overflow-hidden shadow-lg hover:shadow-xl 
                                transition-shadow duration-300"
                            >
                                <Button
                                    onClick={onButtonClick}
                                    className="w-full sm:w-auto rounded-[1.15rem] px-6 sm:px-8 py-4 sm:py-6 text-base sm:text-lg 
                                    font-semibold backdrop-blur-md bg-blue-500/95 hover:bg-blue-500/100 text-white 
                                    transition-all duration-300 group-hover:-translate-y-0.5 border border-blue-400/10
                                    hover:shadow-md shadow-blue-500/30"
                                >
                                    <span className="opacity-90 group-hover:opacity-100 transition-opacity">
                                        {buttonText}
                                    </span>
                                    <span className="ml-2 sm:ml-3 opacity-70 group-hover:opacity-100 
                                        group-hover:translate-x-1.5 transition-all duration-300"
                                    >
                                        →
                                    </span>
                                </Button>
                            </div>
                        </motion.div>
                    )}
                </motion.div>
            </div>
            
            {/* Bottom gradient overlay - only show if showGradients is true */}
            {showGradients && (
                <div className="absolute bottom-0 left-0 right-0 h-48"
                     style={{
                         backgroundImage: 'linear-gradient(to top, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.95) 25%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.4) 75%, rgba(0, 0, 0, 0) 100%)',
                         zIndex: 5
                     }}>
                </div>
            )}
            
            {/* Special black bottom gradient for blending with sections below - only show if showBlackGradient is true */}
            {showBlackGradient && (
                <div className="absolute bottom-0 left-0 right-0 h-64"
                     style={{
                         backgroundImage: 'linear-gradient(to top, rgb(0, 0, 0) 0%, rgba(0, 0, 0) 40%, rgba(0, 0, 0, 0.95) 60%, rgba(0, 0, 0, 0.85) 70%, rgba(0, 0, 0, 0.7) 80%, rgba(0, 0, 0, 0.4) 90%, transparent 100%)',
                         zIndex: 5
                     }}>
                </div>
            )}
        </div>
    );
}