import { PricingCard } from "./pricing-card";
import { useLanguage } from "@/context/LanguageContext";
import { useNavigate } from "react-router-dom";

export function VoicePricingSection() {
  const { t } = useLanguage();
  const navigate = useNavigate();
  
  const voicePlans = {
    starter: {
      tier: "Starter",
      price: "€499",
      monthlyPrice: "€79/mo",
      bestFor: "Best for small businesses",
      CTA: "Start Free Trial",
      benefits: [
        { text: "24/7 Support", checked: true },
        { text: "Basic Call Handling", checked: true },
        { text: "Up to 500 calls/mo", checked: true },
        { text: "Email Support", checked: true },
        { text: "Voice Training", checked: false },
        { text: "Call Analytics", checked: false },
      ]
    },
    pro: {
      tier: "Pro",
      price: "€999",
      monthlyPrice: "€149/mo",
      bestFor: "Best for growing businesses",
      CTA: "Start Free Trial",
      benefits: [
        { text: "24/7 Support", checked: true },
        { text: "Advanced Call Handling", checked: true },
        { text: "Up to 2000 calls/mo", checked: true },
        { text: "Priority Support", checked: true },
        { text: "Voice Training", checked: true },
        { text: "Basic Analytics", checked: false },
      ]
    },
    enterprise: {
      tier: "Enterprise",
      price: "Custom",
      bestFor: "Best for large operations",
      CTA: "Contact Sales",
      benefits: [
        { text: "24/7 Support", checked: true },
        { text: "Full Call Center Integration", checked: true },
        { text: "Unlimited calls", checked: true },
        { text: "24/7 Priority Support", checked: true },
        { text: "Voice Training", checked: true },
        { text: "Advanced Analytics", checked: true },
      ]
    }
  };

  return (
    <section className="relative overflow-hidden bg-background text-foreground">
      <div className="relative z-10 mx-auto max-w-5xl px-4 py-12 md:px-8">
        <div className="mb-6 space-y-2">
          <h2 className="text-center text-3xl font-semibold leading-tight sm:text-4xl sm:leading-tight md:text-5xl md:leading-tight">
            {t('pricing.title')}
          </h2>
          <p className="text-center text-base text-muted-foreground md:text-lg">
            {t('pricing.subtitle')}
          </p>
        </div>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          <div onClick={() => navigate('/getstarted')} className="cursor-pointer">
            <PricingCard {...voicePlans.starter} />
          </div>
          <div onClick={() => navigate('/getstarted')} className="cursor-pointer">
            <PricingCard {...voicePlans.pro} />
          </div>
          <div onClick={() => navigate('/getstarted')} className="cursor-pointer">
            <PricingCard {...voicePlans.enterprise} />
          </div>
        </div>
      </div>
    </section>
  );
}