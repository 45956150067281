import { BackgroundPaths } from "@/components/ui/background-paths";
import { Timeline } from "@/components/ui/timeline";
import { useNavigate } from 'react-router-dom';
import { useLanguage } from "@/context/LanguageContext";
import { SEO } from "@/components/SEO";

export default function AboutPage() {
  const navigate = useNavigate();
  const { t } = useLanguage();

  return (
    <>
      <SEO 
        title="About Serfeo | AI Automation Solutions | Belgium"
        description="Learn about Serfeo's mission to revolutionize business operations with AI automation solutions for restaurants, hotels, and car dealerships."
        schemaType="Organization"
        canonicalOverride="https://serfeo.com/about"
      />
      <div className="bg-black min-h-screen">
        <BackgroundPaths
          title={t('nav.about')}
          description={t('footer.tagline')}
          buttonText={t('cta.button')}
          onButtonClick={() => navigate('/getstarted')}
          showGradients={false}
          showBlackGradient={true}
        />
        <div className="container mx-auto px-4 py-20">
          <div className="max-w-3xl mx-auto text-gray-300 space-y-8">
            <p className="text-xl">
              <strong className="text-white text-2xl block mb-3">{t('aboutPage.mission.title')}</strong>
              {t('aboutPage.mission.description')}
            </p>
            <p className="text-lg">
              <strong className="text-white text-2xl block mb-3">{t('aboutPage.approach.title')}</strong>
              {t('aboutPage.approach.description')}
            </p>
          </div>
        </div>
        <Timeline />
      </div>
    </>
  );
}