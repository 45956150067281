import { Hero } from "@/components/ui/animated-hero";
import { Timeline } from "@/components/ui/timeline";
import { TestimonialsSection } from "@/components/ui/testimonials-section";
import { BackgroundPaths } from "@/components/ui/background-paths";
import { BeamsBackground } from "@/components/ui/beams-background";
import { useNavigate } from 'react-router-dom';
import { useLanguage } from "@/context/LanguageContext";
import { Card } from "@/components/ui/card";
import { Utensils, Building, Car } from "lucide-react";
import { SEO } from "@/components/SEO";

export default function HomePage() {
  const navigate = useNavigate();
  const { t } = useLanguage();
  
  const testimonials = [
    {
      author: {
        name: "John Smith",
        handle: "CEO at TechCorp",
        avatar: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=400&h=400&fit=crop"
      },
      text: "Serfeo has transformed our customer service operations. The AI solutions are incredibly effective."
    },
    {
      author: {
        name: "Sarah Johnson",
        handle: "Operations Director",
        avatar: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=400&h=400&fit=crop"
      },
      text: "Their AI voice agent has significantly improved our customer engagement and response times."
    }
  ];

  return (
    <>
      <SEO 
        title="Serfeo | AI Automation | Chatbots | Intelligent Solutions | Belgium"
        description="Serfeo provides AI automation and chatbot solutions to streamline business operations. Enhance efficiency with smart automation tailored to your needs."
        canonicalOverride="https://serfeo.com"
      />
      <div className="bg-black min-h-screen">
        <BeamsBackground intensity="medium">
          <Hero
            titles={["Automated", "AI Powered", "More Profitable"]}
            subtitle={t('hero.subtitle')}
            ctaText={t('hero.ctaText')}
            onCtaClick={() => navigate('/getstarted')}
            showSolutionsButton={true}
            solutionsSectionId="solutions-section"
            useAuroraBackground={false}
            makeEveryText="Make every task"
          />
        </BeamsBackground>
        
        <Timeline />
        
        {/* Industry Cards Section */}
        <div id="solutions-section" className="py-16 relative">
          {/* Blue glow effects */}
          <div className="absolute inset-0 -z-10">
            <div className="absolute top-1/4 left-1/3 w-[500px] h-[500px] rounded-full bg-blue-500/5 blur-[100px] animate-pulse-blue"></div>
            <div className="absolute bottom-1/4 right-1/4 w-[400px] h-[400px] rounded-full bg-blue-400/5 blur-[80px] animate-pulse-blue" style={{ animationDelay: "2s" }}></div>
          </div>
          
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold text-center mb-12 text-white">{t('industries_section.title')}</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {/* Restaurant Card */}
              <Card 
                className="bg-gray-900/60 hover:bg-gray-900/80 border-gray-800 hover:border-blue-900/30 p-8 text-center cursor-pointer transition-all duration-300 hover:-translate-y-1 group"
                onClick={() => navigate('/restaurants')}
              >
                <div className="flex justify-center mb-6">
                  <div className="w-16 h-16 rounded-full bg-blue-900/20 flex items-center justify-center text-blue-400 group-hover:text-blue-300 transition-colors">
                    <Utensils size={32} />
                  </div>
                </div>
                <h3 className="text-2xl font-bold mb-3 text-white">{t('industries_section.restaurants.title')}</h3>
                <p className="text-gray-400">{t('industries_section.restaurants.description')}</p>
              </Card>
              
              {/* Hotels Card */}
              <Card 
                className="bg-gray-900/60 hover:bg-gray-900/80 border-gray-800 hover:border-blue-900/30 p-8 text-center cursor-pointer transition-all duration-300 hover:-translate-y-1 group"
                onClick={() => navigate('/hotels')}
              >
                <div className="flex justify-center mb-6">
                  <div className="w-16 h-16 rounded-full bg-blue-900/20 flex items-center justify-center text-blue-400 group-hover:text-blue-300 transition-colors">
                    <Building size={32} />
                  </div>
                </div>
                <h3 className="text-2xl font-bold mb-3 text-white">{t('industries_section.hotels.title')}</h3>
                <p className="text-gray-400">{t('industries_section.hotels.description')}</p>
              </Card>
              
              {/* Car Dealerships Card */}
              <Card 
                className="bg-gray-900/60 hover:bg-gray-900/80 border-gray-800 hover:border-blue-900/30 p-8 text-center cursor-pointer transition-all duration-300 hover:-translate-y-1 group"
                onClick={() => navigate('/dealerships')}
              >
                <div className="flex justify-center mb-6">
                  <div className="w-16 h-16 rounded-full bg-blue-900/20 flex items-center justify-center text-blue-400 group-hover:text-blue-300 transition-colors">
                    <Car size={32} />
                  </div>
                </div>
                <h3 className="text-2xl font-bold mb-3 text-white">{t('industries_section.dealerships.title')}</h3>
                <p className="text-gray-400">{t('industries_section.dealerships.description')}</p>
              </Card>
            </div>
          </div>
        </div>
        
        <TestimonialsSection
          title={t('testimonials.title')}
          description={t('testimonials.description')}
          testimonials={testimonials}
        />
        
        <BackgroundPaths
          title={t('cta.title')}
          description={t('cta.description')}
          buttonText={t('cta.button')}
          onButtonClick={() => navigate('/getstarted')}
        />
      </div>
    </>
  );
}