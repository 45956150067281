import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import './index.css';

// Check if there's a redirect path stored in session storage
// This handles redirects from the 404.html page
const redirectPath = sessionStorage.getItem('redirectPath');
if (redirectPath) {
  // Remove the stored path from session storage
  sessionStorage.removeItem('redirectPath');
  
  // Push the stored path to the browser history
  window.history.replaceState(null, '', redirectPath);
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>
);