"use client";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { MoveRight, ArrowLeft } from "lucide-react";
import { Button } from "@/components/ui/button";
import { AuroraBackground } from "./aurora-background"; 
import { BackButton } from "./back-button";
import { useLanguage } from "@/context/LanguageContext";

interface HeroProps {
  titles: string[];
  subtitle: string;
  ctaText?: string;
  onCtaClick?: () => void;
  solutionsSectionId?: string;
  showSolutionsButton?: boolean;
  secondaryCtaText?: string;
  onSecondaryCtaClick?: () => void;
  showBackButton?: boolean;
  useAuroraBackground?: boolean;
  makeEveryText?: string;
}

export function Hero({
  titles,
  subtitle,
  ctaText = "Get Started",
  onCtaClick,
  solutionsSectionId = "solutions-section",
  showSolutionsButton = false,
  secondaryCtaText,
  onSecondaryCtaClick,
  showBackButton = false,
  useAuroraBackground = true,
  makeEveryText
}: HeroProps) {
  const [titleNumber, setTitleNumber] = useState(0);
  const { t } = useLanguage();
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const scrollToSolutions = () => {
    const element = document.getElementById(solutionsSectionId);
    if (element) {
      const offset = 80;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;
      
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };

  useEffect(() => {
    // Clear any existing timeout
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    
    // Set a new timeout
    timeoutIdRef.current = setTimeout(() => {
      if (titleNumber === titles.length - 1) {
        setTitleNumber(0);
      } else {
        setTitleNumber(titleNumber + 1);
      }
    }, 2000);
    
    // Clean up the timeout when the component unmounts or when dependencies change
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [titleNumber, titles]);

  const renderHeroContent = () => (
    <div className="w-full min-h-[60vh] sm:min-h-screen flex items-center justify-center">
      <div className="container mx-auto px-4 md:px-6">
        {showBackButton && (
          <div className="absolute top-6 left-4 sm:left-8 z-20">
            <BackButton />
          </div>
        )}
        <div className="flex gap-2 md:gap-8 py-8 md:py-24 items-center justify-center flex-col mt-0 sm:mt-0">
          {showSolutionsButton && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <Button 
                variant="secondary" 
                size="sm" 
                className="gap-2 md:gap-4 text-sm md:text-base"
                onClick={scrollToSolutions}
              >
                {t('hero.solutions')} <MoveRight className="w-3 h-3 md:w-4 md:h-4" />
              </Button>
            </motion.div>
          )}
          <div className="flex gap-2 md:gap-4 flex-col">
            <h1 className="text-2xl sm:text-4xl md:text-6xl lg:text-7xl max-w-2xl tracking-tighter text-center font-regular px-2">
              <motion.span
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
                className="text-blue-400 block mb-1 md:mb-3 text-3xl sm:text-5xl md:text-6xl lg:text-7xl"
              >
                {makeEveryText || t('hero.makeEvery')}
              </motion.span>
              <span className="relative flex w-full justify-center overflow-hidden text-center md:pb-4 pt-2 md:pt-2 min-h-[2em] sm:min-h-[2em]">
                {titles.map((title, index) => (
                  <motion.span
                    key={index}
                    className="absolute font-semibold text-white"
                    initial={{ opacity: 0, y: "-100" }}
                    transition={{ type: "spring", stiffness: 50 }}
                    animate={
                      titleNumber === index
                        ? {
                            y: 0,
                            opacity: 1,
                          }
                        : {
                            y: titleNumber > index ? -150 : 150,
                            opacity: 0,
                          }
                    }
                  >
                    {title}
                  </motion.span>
                ))}
              </span>
            </h1>

            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className="text-sm sm:text-base md:text-lg lg:text-xl leading-relaxed tracking-tight text-gray-400 max-w-2xl text-center px-4"
            >
              {subtitle}
            </motion.p>
          </div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
            className="w-full sm:w-auto px-4 sm:px-0 flex flex-col sm:flex-row gap-4"
          >
            <Button 
              size="lg" 
              className="gap-3 md:gap-4 w-full sm:w-auto text-sm sm:text-base md:text-lg py-3 sm:py-6 px-6 sm:px-8" 
              onClick={onCtaClick}
            >
              {ctaText} <MoveRight className="w-4 h-4 md:w-5 md:h-5" />
            </Button>
            
            {secondaryCtaText && (
              <Button 
                variant="outline"
                size="lg" 
                className="gap-3 md:gap-4 w-full sm:w-auto text-sm sm:text-base md:text-lg py-3 sm:py-6 px-6 sm:px-8" 
                onClick={onSecondaryCtaClick}
              >
                {secondaryCtaText}
              </Button>
            )}
          </motion.div>
        </div>
      </div>
    </div>
  );

  if (useAuroraBackground) {
    return (
      <AuroraBackground>
        {renderHeroContent()}
      </AuroraBackground>
    );
  }

  return renderHeroContent();
}